/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Menu, ListSelect, OptionSelect, OptionSelectTitle, OptionSelectDescription } from './styles';

interface DataDTO{
  myInv: number;
  otherInv: number;
  allInv: number;
  handleFilter: any;
}

const SelectFilter = ({allInv=0, myInv=0, otherInv=0, handleFilter}:DataDTO):any => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('Meus débitos');
  const [myInvoicesPending, setMyInvoicesPending] = useState(0);
  const [otherInvoicesPending, setOtherInvoicesPending] = useState(0);
  const [allInvoicesPending, setAllInvoicesPending] = useState(0);
  const [actualInvoicesPending, setActualInvoicesPending] = useState(0);

  useEffect(()=>{
    setMyInvoicesPending(myInv);
    setOtherInvoicesPending(otherInv);
    setAllInvoicesPending(allInv);
  },[allInv, myInv, otherInv])

  useEffect(()=>{
    if(title === 'Meus débitos'){
      setActualInvoicesPending(myInvoicesPending)
    }else if(title === 'Clientes anteriores'){
      setActualInvoicesPending(otherInvoicesPending)
    }else{
      setActualInvoicesPending(allInvoicesPending)
    }
  },[title, myInvoicesPending, otherInvoicesPending, allInvoicesPending])

  return (
    <Container style={{ width:'100%'}}>
      <Menu
        disabled={otherInv === 0}
        onClick={()=>{
        setIsOpen(!isOpen);
      }}
        style={{
          transition: '0.3s',
          background: isOpen ? 'var(--secondaryColor)' : 'transparent',
          color: isOpen ? '#fff' : 'var(--secondaryColor)',
          minWidth: isOpen ? '320px' : '200px'
        }}
      >
        <span>
          {title}
        </span>
        <FontAwesomeIcon icon={faChevronDown} style={{marginLeft: 10, visibility: otherInv === 0? 'hidden' : 'visible', marginTop: 3, transition: '0.2s', transform: isOpen ? 'rotate(180deg)': ''}} size='sm'  />
      </Menu>
      <ListSelect style={{
        visibility: isOpen ? 'visible' : 'hidden',
        flexDirection: 'column',
        height: isOpen ? 188 : 0,
        transition: 'height 0.3s',
      }}
      >
        <OptionSelect onClick={()=>{
          setTitle('Meus débitos');
          setIsOpen(false);
          handleFilter('1');
        }}
        >
          <OptionSelectTitle>Meus débitos</OptionSelectTitle>
          <OptionSelectDescription>
            {myInvoicesPending}
            {' '}
            FATURAS ABERTAS
          </OptionSelectDescription>
        </OptionSelect>
        <OptionSelect onClick={()=>{
          setTitle('Clientes anteriores');
          setIsOpen(false);
          handleFilter('0');
        }}
        >
          <OptionSelectTitle>Clientes anteriores</OptionSelectTitle>
          <OptionSelectDescription>
            {otherInvoicesPending}
            {' '}
            FATURAS ABERTAS
          </OptionSelectDescription>
        </OptionSelect>
        <OptionSelect onClick={()=>{
          setTitle('Todos os débitos');
          setIsOpen(false);
          handleFilter(null);
        }}
        >
          <OptionSelectTitle>Todos os débitos</OptionSelectTitle>
          <OptionSelectDescription>
            {allInvoicesPending}
            {' '}
            FATURAS ABERTAS
          </OptionSelectDescription>
        </OptionSelect>
      </ListSelect>
      <span className="spanWeb">
        {actualInvoicesPending > 1 ?
        `${actualInvoicesPending} FATURAS PENDENTES`
        : actualInvoicesPending === 1
        ? `${actualInvoicesPending} FATURA PENDENTES`
        : 'SEM FATURAS PENDENTES'}
      </span>
    </Container>
);
}

export default SelectFilter;
