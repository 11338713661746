import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (max-width: 768px){
    display: flex;
  }
`;

export const Menu = styled.button`
  position: relative;
  background-color: transparent;
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 6px;
  border: none;
  .spanTitle{
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 600;
  }
  .spanDescription{
    font-size: 11px;
    font-family: 'Roboto';
    font-weight: 300;
    text-align: start;
  }
`;

export const Modal = styled.div`
  transition: 0.4s;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 381px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
  position: fixed;
  left: 0;
  z-index: 100;
  -webkit-box-shadow: 0px 1px 27px 6px rgba(0,0,0,0.77);
  box-shadow: 0px 1px 27px 6px rgba(0,0,0,0.77);
`;

export const HeaderModal = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #ccc;
  color: var(--secondaryColor);
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 600;
  button{
    border: none;
    color: var(--secondaryColor);
    background-color: transparent;
  }
`;


export const ListSelect = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  margin-top: 10px;
  z-index: 10;
`;

export const OptionSelect = styled.button`
  background-color: #fff;
  width: 100%;
  height: 58px;
  border-radius: 6px;
  display: flex;
  padding: 6px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #fff;
  transition: 0.2s;
  color: #676767;
  cursor: pointer;
  &:hover{
    border: solid 1px var(--secondaryColor);
    color: var(--secondaryColor);
  }
`;

export const OptionSelectTitle = styled.div`
  font-weight: 600;
  text-align: start;
  font-size: 18px;
`;

export const OptionSelectDescription = styled.div`
  font-weight: 400;
  text-align: start;
  font-size: 11px;
`;

export const ApplyButton = styled.button`
  width: 90%;
  margin: 10px 5%;
  height: 48px;
  border-radius: 8px;
  background-color: var(--secondaryColor);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-family: 'Roboto';
  font-size: 16px;
`;
