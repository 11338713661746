/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'

import * as S from './styles'

interface ModalProps {
  setModalVisible(b: boolean): void
  modalVisible: boolean
}

export default function ModalMandatoryPayment({
  setModalVisible,
  modalVisible,
}: ModalProps): any {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(modalVisible)
    }, 200)
  }, [modalVisible])

  function handleShow(): void {
    setShow(false)

    setTimeout(() => {
      setModalVisible(false)
    }, 100)
  }

  return (
    <S.ModalContainer
      onClick={(e) => {
        e.preventDefault()
        handleShow()
      }}
      visible={modalVisible}
    >
      <S.Container
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        visible={show}
      >
        <S.Header>
          <div />
          <p className="modal_header_title">Pagamento obrigatório</p>
          <img
            onClick={(e) => {
              e.preventDefault()
              handleShow()
            }}
            alt="x"
            src={`${process.env.PUBLIC_URL}/images/close_white.svg`}
          />
        </S.Header>
        <S.Body>
          <img
            alt="Obrigatorio"
            src={`${process.env.PUBLIC_URL}/images/fatura-obrigatoria.svg`}
          />
          <div className="main_container">
            <p className="modal_text">
              Esta UC possui faturas com débitos de pagamento obrigatório.
            </p>
            <button
              onClick={(e) => {
                e.preventDefault()
                handleShow()
              }}
              className="modal_button"
              type="button"
            >
              {' '}
              ENTENDI
              {' '}
            </button>
          </div>
        </S.Body>
      </S.Container>
    </S.ModalContainer>
  )
}

interface ModalPrioridadeProps {
  setModalVisible(b: boolean): void
  modalVisible: boolean
  markOld: boolean;
  startShake(b:boolean):void
}

export function ModalPrioridade({
  modalVisible,
  setModalVisible,
  markOld,
  startShake
}: ModalPrioridadeProps): any {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(modalVisible)
    }, 200)
  }, [modalVisible])

  function handleShow(): void {
    setShow(false)

    setTimeout(() => {
      setModalVisible(false)
      startShake(markOld)
    }, 100)
  }

  return (
    <S.ModalContainer
      onClick={(e) => {
        e.preventDefault()
        handleShow()
      }}
      visible={modalVisible}
    >
      <S.Container
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        visible={show}
      >
        <S.Header>
          <div />
          <p className="modal_header_title">Prioridade de pagamento</p>
          <img
            onClick={(e) => {
              e.preventDefault()
              handleShow()
            }}
            alt="x"
            src={`${process.env.PUBLIC_URL}/images/close_white.svg`}
          />
        </S.Header>
        <S.Body>
          <img
            alt="Obrigatorio"
            src={`${process.env.PUBLIC_URL}/images/fatura-obrigatoria.svg`}
          />
          <div className="main_container">
            <p className="modal_text">
              {markOld
                ? 'A prioridade de pagamento são das faturas mais antigas. Marque a partir da fatura mais antiga.'
                : 'A prioridade de pagamento são das faturas mais antigas. Tente desmarcar faturas mais recentes.'}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault()
                handleShow()
              }}
              className="modal_button"
              type="button"
            >
              {' '}
              Ok, entendi
              {' '}
            </button>
          </div>
        </S.Body>
      </S.Container>
    </S.ModalContainer>
  )
}
