import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  gap: 10px;
  justify-content: center;
  color: white;
  border-left: 6px solid red;
  border-bottom: none;
  width: 300px;
  height: 80px;
  position: absolute;
  border-radius: 8px;
  background-color: #000;
  z-index: 20;
  right: 20px;
  bottom: 20px;
  img{
    width: 30px;
    height: 30px;
  }
  span{
    font-weight: 500;
  }
  @media only screen and (max-width: 768px){
    margin: auto auto;
    right: 20px;
    left: 20px;
    border-left: none;
    border-bottom: 6px solid red;
  }
`;
