import {createStore} from 'redux';
import { StateInitial } from '../utils/models';


const INITIAL_STATE:StateInitial = {
  DATA_CLIENT: {
    CEP_LOGRADOURO: '',
    COMPLEMENTO:  '',
    FLAG_CLIENTE_ATUAL:  '',
    IDENT_CLIENTE_FATURA:  '',
    IDENT_CLIENTE_UC_ATUAL:  '',
    IDENT_UC:  '',
    LOGR_TIPO_NOME:  '',
    NOME_BAIRRO:  '',
    NOME_CLIENTE:  '',
    NOME_LOCAL:  '',
    NOME_MUNICIPIO:  '',
    NUMERO_DOCTO_1:  '',
    NUMERO_IMOVEL:  '',
    TIPO_DOCTO:  '',
    LATITUDE: '',
    LONGITUDE: ''
  },
  NOME_CLIENTE:  '',
  loading: true,
  SHOW_MODAL: false,
  CONNECTION: true,
  SEARCH_DB: false,
  LIMITED: true,
};

function reducer(state = INITIAL_STATE, action: any): any {
  switch (action.type) {
    case 'SET_LIMITED':
      return {...state, LIMITED:action.limited}
    case 'SET_LOADINGDB':
      return {...state, SEARCH_DB: action.showLoading};
    case 'CONNECTION_CHANGED':
      return {...state, CONNECTION: action.connection};
    case 'STOP_LOADING':
      return {...state, loading: action.show};
    case 'SET_NAME':
      return {...state, NOME_CLIENTE: action.name};
    case 'SET_UC':
      return {...state, DATA_CLIENT: action.data};
    case "RESET":
      return INITIAL_STATE;
    case 'SET_VISIBILITY_MODAL':
      return {...state, SHOW_MODAL: action.showModalWhenEmptyDoc};
    default:
      return state;
  }
}

const store = createStore(reducer);

export default store;
