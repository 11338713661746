/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, ModalContent } from './styles';

interface ModalDTO{
  showModalErrorMundi: boolean;
}

const Modal = ({showModalErrorMundi}: ModalDTO):any => {

  const history = useHistory();

  const [delay, setDelay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(showModalErrorMundi)
  },[showModalErrorMundi])

  useEffect(() => {
    setTimeout(() => {
      setDelay(isVisible);
    }, 200);
  },[isVisible]);

  return (
    <Container
      style={{
        transition: '0.3s',
        display: delay ? 'flex' : isVisible ? 'flex' : 'none',
        opacity: isVisible ? delay ? 1 : 0 : 0
      }}
    >
      <ModalContent>
        <div>
          <h2>Ops!!</h2>
          <p>
            Ocorreu um erro ao processar o seu cartão.
            Isso não é culpa sua,
            tente novamente mais tarde.
          </p>
          <button
            onClick={() => {
              setIsVisible(false);
              setTimeout(() => {
                history.push('/');
              }, 300);
            }}
            type="button"
          >
            Continuar
          </button>
        </div>
      </ModalContent>
    </Container>
  );
}

export default Modal;
