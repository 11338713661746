/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useField } from '@unform/core';
import { Container, Label,  BoxInput } from './styles';

interface Props {
  name: string;
  label?: string;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;
const Input: React.FC<InputProps> = ({ name, label, ...rest }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [errorField, setErrorField] = useState<boolean>(false);

  useEffect(()=>{
    if(error){
      setErrorField(true)
    }else{
      setErrorField(false)
    }
  },[error])

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField]);
  return (
    <Container>
      { label && <Label>{label}</Label> }
      <BoxInput>
        <input
          id={fieldName}
          className={errorField ? 'error' : ''}
          ref={inputRef}
          defaultValue={defaultValue}
          onFocus={()=>{setErrorField(false)}}
          {...rest}
        />
        {!errorField
          ? <div />
          : <FontAwesomeIcon icon={faExclamationCircle} color='#eb4646' size='1x' title={errorField ? `*${error}` : ''} />}
      </BoxInput>
    </Container>
  );
};
export default Input;
