import { FaturaDTO } from "./models"

// export function checkIfNewTypeFixed(faturas: FaturaDTO[]): boolean {
//   const temp = [...faturas]
//   const filtered = temp.filter((fatu) => fatu.motivoFd === '51')

//   if (filtered.length > 0 && filtered.length === temp.length) return true
//   return false
// }

export function checkIfNewTypeFixed(faturas: FaturaDTO[]): boolean {
 return false
}
