/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { StateInitial } from '../../utils/models'

import * as S from './styles'

interface ModalProps {
  setModalVisible(b: boolean): void
  modalVisible: boolean
}

export default function ModalAnalise({
  setModalVisible,
  modalVisible,
}: ModalProps): any {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(modalVisible)
    }, 200)
  }, [modalVisible])

  function handleShow(): void {
    setShow(false)

    setTimeout(() => {
      setModalVisible(false)
    }, 100)
  }

  return (
    <S.ModalContainer visible={modalVisible}>
      <S.Container visible={show}>
        <S.Header>
          <div />
          <p className="modal_header_title">Faturas em analise</p>
          <img
            onClick={(e) => {
              e.preventDefault()
              handleShow()
            }}
            alt="x"
            src={`${process.env.PUBLIC_URL}/images/close_white.svg`}
          />
        </S.Header>
        <S.Body>
          <img
            alt="Obrigatorio"
            src={`${process.env.PUBLIC_URL}/images/fatura-obrigatoria.svg`}
          />
          <div className="main_container">
            <p className="modal_text">
              Esta UC possui faturas em análise. Em caso de dúvidas ligue
              {' '}
              {process.env.REACT_APP_PAYMENT_ERROR_PHONE}
              {' '}
              ou procure uma de nossas lojas de atendimento.
            </p>
            <button
              onClick={(e) => {
                e.preventDefault()
                handleShow()
              }}
              className="modal_button"
              type="button"
            >
              {' '}
              ENTENDI
              {' '}
            </button>
          </div>
        </S.Body>
      </S.Container>
    </S.ModalContainer>
  )
}
