/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PaymentDTO } from '../../../../utils/models'

import {
  Container,
  Box,
  Title,
  Top,
  Bottom,
  Button,
  TextFooterBox,
  TableInvoices,
} from './styles'

// eslint-disable-next-line react/prop-types
const Error: React.FC<{ returnToPayment(): void }> = ({ returnToPayment }) => {
  const location = useLocation<unknown>()
  const history = useHistory()
  const [paymentResume, setPaymentResume] = useState<PaymentDTO>()
  const [totalCart, setTotalCart] = useState(0)

  useEffect(() => {
    const tempPaymentDefault = localStorage.getItem('paymentResume')
    if (tempPaymentDefault) {
      setPaymentResume(JSON.parse(tempPaymentDefault))
      setTotalCart(JSON.parse(tempPaymentDefault).total)
    } else {
      history.push('/')
    }
  }, [history, location])

  return (
    <Container>
      <Box>
        <Title>Pagamento não efetuado</Title>
        <Top>
          <div>
            <span>Fatura</span>
            <span>Valor</span>
          </div>
          <TableInvoices>
            {paymentResume?.faturas?.map((invoice) => {
              return (
                <div key={invoice.id}>
                  <div>
                    <span>{invoice.ref}</span>
                    <span>{`R$ ${invoice.total.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                    })}`}</span>
                  </div>
                </div>
              )
            })}
          </TableInvoices>
          <TableInvoices>
            <div>
              <div>
                <span>Total</span>
                <span>
                  {`R$ ${totalCart.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                  })}` || '0,00'}
                </span>
              </div>
            </div>
          </TableInvoices>
        </Top>
        <Bottom>
          <img
            src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/icons/information-red.svg`}
            alt="Info"
          />
          <span>
            Não foi possível processar o pagamento da sua fatura, tente outra
            forma de pagamento ou entre em contato conosco.
          </span>
        </Bottom>
        <Button
          onClick={() => {
            returnToPayment()
          }}
        >
          TENTAR PAGAR NOVAMENTE
        </Button>
        <TextFooterBox>
          <h4>{`Fale com a  ${process.env.REACT_APP_HELMET_OG_TITLE}`}</h4>
          <h1>{` Ligue  ${process.env.REACT_APP_PAYMENT_ERROR_PHONE}`}</h1>
        </TextFooterBox>
      </Box>
    </Container>
  )
}
export default Error
