import React from 'react';

import { Box, Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <Box>
        <a href="https://usebill.com.br/" target="__blank">
          Arrecadação Multicanal -
          <img color="#fff" src={`${process.env.PUBLIC_URL}/images/logo-bill-black.svg`} alt="Pigz Pay" />
        </a>
        <div className='OrangeFooter'>
          © Orange Labs 2021
          {/* <h3 style={{marginLeft: 5}}></h3> */}
        </div>
      </Box>
    </Container>
);
}

export default Footer;
