import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px;
  height: 140px;
  z-index: 11;
  justify-content: center;
  @media only screen and (max-width: 768px){
    padding: 20px 10px;
    height: 120px;
  }
`;

export const BarLoading = styled.section`
  width: 1198.5px;
  height: 3px;
  margin: 0 10px;
  top: 106.5px;
  position: absolute;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient(
    -90deg,
    var(--primaryColor) 0%,
    var(--primaryColor) 50%,
    #fff 100%
  );
  background-size: 100% 100%;
  animation: animation 50s ease-in-out infinite;
  @keyframes animation {
    0% {
      background-position: -10000px 0;
    }
    100% {
      background-position: 10000px 0;
    }
  }
  @media only screen and (max-width: 768px){
    /* width: 100%;
    margin: 0px;
    left: 0px;
    top: 87px; */
  }
`;

export const Box = styled.div`
  max-width: 1250px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  box-shadow: 0px 6px 24px #0000001A;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  background-color: #fff;
  border-radius: 24px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${props => props.draggable ? 'var(--primaryColor)' : '#fff'};
  >div{
    margin: 0;
    transition: 0.3s;
    padding: 5px 0;
    flex: 1;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .logoGrid{
      margin: 0;
      .logoBig{
        display: flex;
      }
      .logoSmall{
        display: none;
      }
    }
  }
  #textTop{
    display: flex;
    align-items: center;
  }
  #textViewMobile{
    display: none;
  }
  #textBody{
    display: none;
  }
  #textViewPc{
      display: flex;
    }
  overflow: hidden;
  @media only screen and (max-width: 768px){
    #textViewPc{
      display: none;
    }
    #textViewMobile{
      width: 100%;
      margin-left: 20px;
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
    >div{
      .logoGrid{
        margin: 0;
        .logoBig{
          display: none;
        }
        .logoSmall{
          display: flex;
          max-width: 100%;
        }
      }
      >div:last-child{
        display: flex;
      }
    }
    div#textBody{
      display: flex;
      flex-direction: column;
      >div{
        >div{
          display: none;
        }
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 10px;
        gap: 20px;
        margin-top: 5px;
        border-top: 0.5px solid #9e9e9e;
      }
    }
    padding: 15px 20px 20px 20px;
    width: 100vw;
    height: 70px;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    >div{
      align-items: flex-start;
    }
    img{
      height: 30px;
    }
    #adress, #client {
      display: none !important;
    }
  }
`;

export const Label = styled.span`
  line-height: 1.5;
  color: #676767;
  font-size: 10px;
  font-family: 'Roboto';
  @media only screen and (max-width: 768px){
    font-size: 10pt;
    margin-top: 10px;
  }
`;

export const Content = styled.span`
  line-height: 1.5;
  color: #494949;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto';
  @media only screen and (max-width: 768px){
    margin-right: 10px;
  }
`;

export const Logout = styled.button`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color:#EDF1F5;
  transition: 300ms;
  &:active{
    background-color: var(--primaryColor);
  }
`;

export const Menu = styled.button`
  display: none;
  border: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color:#EDF1F5;
  transition: 300ms;
  &:active{
    background-color: var(--primaryColor);
  }
  @media only screen and (max-width: 768px){
    display: flex;
    padding: 5px;
  }
`;
