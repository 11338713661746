import React, { useEffect, useState } from 'react';

import {faExclamationTriangle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  ContainerMobile,
  Box,
  Close,
  Text,
  WebContainer,
  WebModal,
  HeaderModal,
  BodyModal,
  BodyRightModal
} from './styles';

interface DataDTO{
  otherInvoices: number;
}

const Notification = ({otherInvoices}:DataDTO):any => {

  const [showNotification, setShowNotification] = useState(false);

  useEffect(()=>{
    const aviso = localStorage.getItem('show_notification');
    if(!aviso && otherInvoices > 0){
      setShowNotification(true);
    }
  },[otherInvoices]);

  function handleClose():void{
    localStorage.setItem('show_notification','true');
    setShowNotification(false);
  }

  return (
    <>
      <ContainerMobile draggable={showNotification}>
        <Box>
          <FontAwesomeIcon icon={faExclamationTriangle} color="#fff" size='1x' />
          <Text>
            Esta Unidade Consumidora (UC)
            <br />
            possui débitos de clientes anteriores.
          </Text>
          <Close onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} color="#fff" size='1x' />
          </Close>
        </Box>
      </ContainerMobile>
      <WebContainer style={{visibility: showNotification ? 'visible' : 'hidden'}}>
        <WebModal>
          <HeaderModal>
            <button style={{height: 50, width: 50,background: 'transparent', border: 'none'}} type="button" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} color="var(--secondaryColor)" style={{height: 20, width: 20}} />
            </button>
            <span>Débitos de clientes anteriores</span>
            <button style={{height: 50, width: 50,background: 'transparent', border: 'none'}} type="button" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} color="#fff" style={{height: 20, width: 20}} />
            </button>
          </HeaderModal>
          <BodyModal>
            <img src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/warning.svg`} alt="" />
            <BodyRightModal>
              <span>
                Esta Unidade Consumidora (UC) possui débitos de clientes anteriores.
              </span>
              <button onClick={handleClose} type="button">
                ENTENDI
              </button>
            </BodyRightModal>
          </BodyModal>
        </WebModal>
      </WebContainer>
    </>
  );
}

export default Notification;
