import styled from 'styled-components';

export const Container = styled.div`
  //width: 280px;
  flex:1;
  height: 40px;
`;
export const Label = styled.label`
  color:#fff;
  height: 10px;
  margin-bottom: 20px;
`;

export const Span = styled.span`
  font-size: 14px;
  color:#F00;
  height: 10px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px){
    margin-bottom: 0px;
    font-size: 12px;
    height: 8px;
  }
`;

export const InputText = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 16px;
  border: none;
  text-align: left;
  border: 0.5px solid #fff;
  padding-left: 16px;
  font-size: 16px;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.error{
    animation: shake 0.2s ease-in-out 0s 2;
    //border: 0.5px solid red;
  }
  @keyframes shake {
    0% { margin-left: 0rem; }
    20% { margin-left: 0.2rem; }
    50% { margin-left: -0.2rem; }
    80% { margin-left: 0.2rem; }
    100% { margin-left: 0rem; }
  }
  @media only screen and (max-width: 768px){
   // margin-top: 10px;
    margin-bottom: 0px;
  }
`;
