import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: #F2F3F3;
  align-items: center;
  justify-content: center;
  gap: 40px;
  div{
    position: absolute;
    width: 100%;
    height: 70px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondaryColor);
    img {
      height: 40px;
    }
  }
  span {
    color: var(--secondaryColor);
    font-size: 45px;
    font-weight: 600;
  }
  button {
    background-color: var(--secondaryColor);
    border-radius: 8px;
    width: 470px;
    height: 50px;
    border: none;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
  }
  img {
    height: 260px;
  }
`;
