/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf'
import { useBarcode } from '@createnextapp/react-barcode'
// eslint-disable-next-line import/no-duplicates
import { parseISO, format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import pt from 'date-fns/locale/pt-BR'
import {
  Font,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from '@react-pdf/renderer'
import { InvoiceDTO, User, PaymentDTO } from '../../utils/models'
import {
  Container,
  Line,
  BlackLine,
  Column,
  Row,
  TableInvoices,
  Bold,
  Right,
  Left,
  Header,
  Title,
  Cliente,
  Field,
  Main,
} from './styles'
import font from '../../assets/fonts/Roboto/Roboto-Bold.ttf'
import fontMedium from '../../assets/fonts/Roboto/Roboto-Medium.ttf'
import regular from '../../assets/fonts/Roboto/Roboto-Light.ttf'

interface InvDTO {
  invoice: InvoiceDTO
  idInvoice: string | undefined
}

interface InvProofDTO {
  proof: PaymentDTO
}

export const PaymentSlip = ({ idInvoice, invoice }: InvDTO): any => {
  const [client, setClient] = useState<User>()

  useEffect(() => {
    const tempClient = localStorage.getItem('info')
    if (tempClient) {
      const temp = JSON.parse(tempClient)
      setClient(temp)
      const divId = document.getElementById('pdfPrint') as HTMLElement
      const printDoc = new jsPDF('portrait', 'px', 'A4')
      printDoc.addFileToVFS(
        '../../assets/fonts/Roboto/Roboto-Medium.ttf',
        'Roboto'
      )
      if (divId && invoice.NOTA_FISCAL === idInvoice) {
        printDoc.html(divId, {
          callback(pdf) {
            pdf.save(`Boleto_${invoice.DT_REF_EDT}.pdf`)
          },
        })
      }
    }
  }, [invoice, idInvoice])

  const { inputRef } = useBarcode({
    value: invoice ? (invoice.BARCODE === '' ? '0' : invoice.BARCODE) : '0',
    options: {
      displayValue: false,
      background: '#fff',
    },
  })

  function ucFirstAllWords(str: string): string {
    if (str.length >= 20) {
      const pieces = str.toLowerCase().split(' ')
      for (let i = 0; i < pieces.length; i += 1) {
        if (i === 1) {
          const j = pieces[i].charAt(0).toUpperCase()
          pieces[i] = `${j}.`
        } else {
          const j = pieces[i].charAt(0).toUpperCase()
          pieces[i] = j + pieces[i].substr(1)
        }
      }
      return pieces.join(' ')
    }
    return str
  }

  return (
    <Container id="pdfPrint" style={{ margin: 'auto', width: 450 }}>
      <div
        style={{ width: '100%', height: 10, background: 'var(--primaryColor)' }}
      />
      <div
        style={{
          padding: '15px 25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Column
          style={{ alignItems: 'center', width: '100%', marginBottom: 25 }}
        >
          <Row>
            <Column>
              <img
                alt="Logo"
                height={30}
                width={140}
                src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logo.jpg`}
                style={{ marginBottom: 10 }}
              />
              <span style={{ fontSize: 8, fontWeight: 300 }}>
                {`${process.env.REACT_APP_ADRESS}`}
              </span>
              <span style={{ fontSize: 8, fontWeight: 300 }}>
                {`CNPJ: ${process.env.REACT_APP_CNPJ}  · IE: ${process.env.REACT_APP_IE}`}
              </span>
            </Column>
            <Column style={{ textAlign: 'end' }}>
              <span style={{ fontSize: 20, fontWeight: 800, lineHeight: 0.9 }}>
                Via para
                <br />
                Pagamento
              </span>
              <span style={{ fontSize: 10, fontWeight: 300, marginTop: 5 }}>
                {'(Art. 330, Res. ANEEL 1.000/21)'}
              </span>
            </Column>
          </Row>
        </Column>
        <Row>
          <Column>
            <span style={{ fontWeight: 500, fontSize: 13, width: '100%' }}>
              {ucFirstAllWords(invoice.NOME_CLIENTE || '-').substr(0, 20)}
            </span>
            <span style={{ fontWeight: 600, fontSize: 12, marginBottom: 10 }}>
              {`UC ${invoice.IDENT_UC || '-'}`}
            </span>
            <span style={{ fontSize: 8, fontWeight: 300, lineHeight: 1.3 }}>
              {`${client?.LOGR_TIPO_NOME || '-'} - ${
                client?.NOME_BAIRRO || '-'
              }`}
            </span>
            <span style={{ fontSize: 8, fontWeight: 300, lineHeight: 1.3 }}>
              {`${client?.NOME_LOCAL || '-'}`}
            </span>
            <span style={{ fontSize: 8, fontWeight: 300, lineHeight: 1.3 }}>
              {`CEP ${client?.CEP_LOGRADOURO || '-'}`}
            </span>
          </Column>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              padding: 5,
              width: 182,
              height: '100%',
              border: '1.5px solid var(--primaryColor)',
              borderRadius: 8,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                background: '#F8F9F2',
                border: '1px solid var(--primaryColor)',
                borderRadius: 6,
              }}
            >
              <span style={{ fontSize: 8 }}>NOTA FISCAL</span>
              <span style={{ fontSize: 10, fontWeight: 500 }}>
                {`${invoice.NOTA_FISCAL || '-'}`}
              </span>
            </div>
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                background: '#F8F9F2',
                border: '1px solid var(--primaryColor)',
                borderRadius: 6,
              }}
            >
              <span style={{ fontSize: 8 }}>MÊS FATURADO</span>
              <span style={{ fontSize: 10, fontWeight: 500 }}>
                {`${invoice.DT_REF_EDT || '-'}`}
              </span>
            </div>
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                background: '#F8F9F2',
                border: '1px solid var(--primaryColor)',
                borderRadius: 6,
              }}
            >
              <span style={{ fontSize: 8 }}>VENCIMENTO</span>
              <span style={{ fontSize: 10, fontWeight: 500 }}>
                {`${invoice.DT_VENCIMENTO_EDT || '-'}`}
              </span>
            </div>
          </div>
        </Row>
        <Row style={{ marginTop: 30, alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: 20,
              background: '#F8F9F2',
              width: 120,
              height: 120,
              borderRadius: 16,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/lightning.png`}
              alt="Energia"
              height={30}
            />
            <span style={{ fontSize: 8, marginTop: 5 }}>seu consumo foi</span>
            <span style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}>
              {`${invoice.CONSUMO || '-'} kWh`}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              background: 'var(--primaryColor)',
              color: '#fff',
              borderRadius: '50%',
              width: 30,
              height: 30,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 115,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/arrow-right-short.png`}
              alt="Seta"
              height={10}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: 20,
              background: '#F8F9F2',
              width: 120,
              height: 120,
              borderRadius: 16,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/calendar.png`}
              alt="Energia"
              height={30}
            />
            <span style={{ fontSize: 8, marginTop: 5 }}>no período de</span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 500,
                marginTop: 10,
                textAlign: 'center',
              }}
            >
              {`${invoice.DT_LEIT_ANT || '-'}`}
              <br />
              {`a ${invoice.DT_LEIT_ATUAL || '-'}`}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              background: 'var(--primaryColor)',
              color: '#fff',
              borderRadius: '50%',
              width: 30,
              height: 30,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 255,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/arrow-right-short.png`}
              alt="Seta"
              height={10}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: 20,
              background: '#F8F9F2',
              width: 120,
              height: 120,
              borderRadius: 16,
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/dollar.png`}
              alt="Energia"
              height={30}
            />
            <span style={{ fontSize: 8, marginTop: 5 }}>
              o valor da fatura é
            </span>
            <span style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}>
              {`R$ ${
                Number(invoice.C1_TOTAL_CORRIGIDO).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </div>
        </Row>
        <Column style={{ width: '100%', marginTop: 20 }}>
          <div>Detalhes da Fatura</div>
          <Line />
          <Row>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              Valor original
            </span>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              {`R$ ${
                Number(invoice.TOTAL_FATURA).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </Row>
          <Row>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              Juros
            </span>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              {`R$ ${
                Number(invoice.C1_JUROS).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </Row>
          <Row>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              Multa
            </span>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              {`R$ ${
                Number(invoice.C1_MULTA).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </Row>
          <Row>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              Correção
            </span>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              {`R$ ${
                Number(invoice.C1_CORRECAO).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </Row>
          <div
            style={{
              display: 'flex',
              opacity:
                invoice.SIT_FATURA_EDT === 'ABER' ||
                invoice.SIT_FATURA_EDT === 'REAV'
                  ? 1
                  : 0,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ width: '100%', height: 40, marginTop: 20 }}
              ref={inputRef}
              alt="Código de Barras"
            />
            <span style={{ marginTop: 10, fontSize: 12 }}>
              {invoice.BARCODE_LINE}
            </span>
          </div>
        </Column>
      </div>
      <div
        style={{
          marginTop: 15.5,
          alignItems: 'center',
          padding: '5px 25px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          background: 'var(--secondaryColor)',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: 7, color: '#fff' }}>
            {`${process.env.REACT_APP_PDF_FOOTER}`}
          </span>
        </div>
        <div
          style={{
            fontSize: 7,
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Pagamento oferecido por
          <img
            src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/pigzpay-logotipo-colorido.png`}
            alt="Pigz Pay"
            style={{ height: 9, margin: 3 }}
          />
        </div>
      </div>
    </Container>
  )
}

// Register Font
Font.register({
  family: 'Roboto',
  src: font,
})

Font.register({
  family: 'Roboto-Bold',
  src: fontMedium,
})

Font.register({
  family: 'Regular',
  src: regular,
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'rgb(242, 249, 253)',
    opacity: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
    marginTop: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
  },
  logo: {
    width: 150,
    height: 36,
  },
  logotipo: {
    width: '30%',
    height: 12,
    marginTop: 0,
    backgroundSize: 'cover',
  },
  left: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.6,
  },
  infovalorigin: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.3,
  },
  title: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 700,
    marginTop: 3,
  },
  right: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    alignItems: 'flex-end',
    color: '#2B2B2B',
    lineHeight: 1.6,
  },
  infovalurosright: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    alignItems: 'flex-end',
    color: '#2B2B2B',
    lineHeight: 1.3,
  },
  Field: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  Regular: {
    fontSize: 12,
    fontFamily: 'Regular',
  },
  infofat: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'Roboto',
    marginLeft: 3,
  },
  infofatval: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Roboto',
    marginRight: 3,
  },
  comprovante: {
    marginTop: 9,
    fontSize: 24,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 22,
  },
  client: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  infoCpf: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Regular',
  },
  infofooter: {
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Regular',
    color: '#494949',
  },
  cpf: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 3,
    fontFamily: 'Regular',
    textAlign: 'left',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 17,
    justifyContent: 'space-between',
  },
  TableInvoices: {
    border: '2.5px solid #2B2B2B',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: 9,
    padding: 0,
    marginBottom: 2,
    marginTop: 7,
  },
  container: {
    borderTop: 'none',
  },
  content: {
    // border: '2.5px solid #2B2B2B',
    // padding: 5,
    width: '100%',
  },
  headerinv: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  payment: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: 12,
    fontFamily: 'Regular',
    letterSpacing: 0.5,
    // marginTop: 7,
    marginLeft: 7,
  },
  paymentTot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 18,
  },
  infoevento: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    lineHeight: 1.3,
  },
  event: {
    fontSize: 12,
    color: '#2B2B2B',
    fontFamily: 'Roboto-Bold',
  },
  BlackLine: {
    marginTop: 7,
    marginBottom: 7,
    display: 'flex',
    width: '100%',
    height: '1.5',
    backgroundColor: '#000',
  },
  BlackLinefat: {
    marginTop: 5,
    display: 'flex',
    width: '100%',
    height: '1.5',
    backgroundColor: '#000',
  },
  noBorder: {
    height: 0,
    width: '100%',
  },
  'BlackLinefat:lastChild': {
    height: '0',
    borderTop: 0,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infocredit: {
    fontSize: 12,
    fontFamily: 'Regular',
    color: '#2B2B2B',
  },
})

// Create Document Component
export const ProofPayment = ({ proof }: InvProofDTO): any => {
  const [client, setClient] = useState<User>()
  const [limited, setLimited] = useState(true)

  useEffect(() => {
    const tempClient = localStorage.getItem('info')
    if (tempClient) {
      const temp = JSON.parse(tempClient)
      const infoPermit = sessionStorage.getItem('infoPermission') === 'true'
      setLimited(!infoPermit)

      setClient(temp)
    }
  }, [proof])

  function dataFormat(data: string): string {
    const Date = parseISO(data)
    const formattedDate = format(Date, 'EEEEEE, dd/MM/yyyy HH:mm', {
      locale: pt,
    })

    return formattedDate.toUpperCase().replace('  ', '')
  }

  const logo = `${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/logo-black.png`
  const logoTipo = `${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/pigzpay-black.png`

  return (
    <Document>
      <Page size={{ width: 377.94 }} style={styles.page}>
        <View style={styles.main}>
          <View style={styles.header}>
            <View style={styles.left}>
              <View>
                <Image src={logo} style={styles.logo} />
              </View>
              <View style={styles.title}>
                <Text>{process.env.REACT_APP_HELMET_OG_TITLE}</Text>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.Field}>
                <Text>ONLINE</Text>
              </View>
              <View style={styles.Regular}>
                <Text>{dataFormat(`${proof?.cadastro}`)}</Text>
              </View>
              <View style={styles.Field}>
                <Text>
                  {process.env.REACT_APP_CNPJ
                    ? process.env.REACT_APP_CNPJ.replace(' ', '')
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.comprovante}>
            <Text>Comprovante de Pagamento</Text>
          </View>
          <View style={styles.info}>
            {!limited && (
              <View style={styles.client}>
                <Text>{`${client?.NOME_CLIENTE}`}</Text>
              </View>
            )}
            <View style={styles.client}>
              <Text>{`UC ${client?.IDENT_UC}`}</Text>
            </View>
          </View>
          {!limited && (
            <View style={styles.cpf}>
              <Text style={styles.infoCpf}>
                {`CPF ${client?.NUMERO_DOCTO_1}`}
              </Text>
            </View>
          )}
          <View style={styles.tags}>
            <View style={styles.infofat}>
              <Text>FATURA</Text>
            </View>
            <View style={styles.infofatval}>
              <Text>VALOR</Text>
            </View>
          </View>
          <View style={styles.TableInvoices}>
            {proof?.faturas.map((inv, i) => {
              return (
                <View key={inv.id} style={styles.container}>
                  <View style={styles.content}>
                    <View style={styles.headerinv}>
                      <View style={styles.infovalorigin}>
                        <View style={styles.client}>
                          <Text>{inv.ref}</Text>
                        </View>
                        <View style={styles.infocredit}>
                          <Text>
                            {`VALOR ORIGINAL R$ ${inv.fatura.toLocaleString(
                              'pt-BR',
                              { minimumFractionDigits: 2 }
                            )}`}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.infovalurosright}>
                        <View style={styles.client}>
                          <Text>
                            {`R$ ${inv.total.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}`}
                          </Text>
                        </View>
                        <View style={styles.infocredit}>
                          <Text>
                            {`J+M+C R$  ${(
                              inv.correcao +
                              inv.juros +
                              inv.multa
                            ).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}`}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={
                        i === proof?.faturas.length - 1
                          ? styles.noBorder
                          : styles.BlackLinefat
                      }
                    />
                  </View>
                </View>
              )
            })}
          </View>
          <View style={styles.tags}>
            <View style={styles.infofat}>
              <Text>CRÉDITO</Text>
            </View>
            <View style={styles.infofat}>
              <Text>
                {`${proof?.transacoes[0].parcelas}X SEM JUROS DE R$ ${(
                  proof?.total / parseFloat(proof?.transacoes[0].parcelas)
                ).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </Text>
            </View>
          </View>
          <View style={styles.payment}>
            <Text>{`CÓD. AUT ${proof?.transacoes[0].atk}`}</Text>
            {
              // <Text>{`ID ${proof?.transacoes[0].id}`}</Text>
            }
            <Text>{`PAN ${proof?.transacoes[0].pan}`}</Text>
          </View>
          <View style={styles.paymentTot}>
            <View style={styles.client}>
              <Text>Valor Total</Text>
            </View>
            <View style={styles.client}>
              <Text>
                {`R$ ${Number(`${proof?.total}`).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`}
              </Text>
            </View>
          </View>
          {process.env.REACT_APP_EVENT_INFO ? (
            <View style={styles.infoevento}>
              <View style={styles.event}>
                <Text>{process.env.REACT_APP_EVENT_INFO}</Text>
              </View>
              {process.env.REACT_APP_EVENT_INFO_SECOND_LINE ? (
                <View style={styles.event}>
                  <Text>{process.env.REACT_APP_EVENT_INFO_SECOND_LINE}</Text>
                </View>
              ) : null}
            </View>
          ) : null}
          <View style={styles.BlackLine} />
          <View style={styles.footer}>
            <View style={styles.infofooter}>
              <Text>Arrecadação Multicanal - </Text>
            </View>
            <View style={styles.logotipo}>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logo-bill.png`}
                style={styles.logotipo}
              />
            </View>
            <View style={styles.infofooter}>
              <Text>© Orange Labs 2021</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
