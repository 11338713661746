/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  faArrowRight,
  faCheck,
  faCheckCircle,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Lottie from 'react-lottie'
import { isAfter } from 'date-fns'

import { borderRadius } from 'react-select/src/theme'
import downloadLottie from '../../assets/files/arquivo-baixado.json'
import copyLottie from '../../assets/files/copiar-codigo.json'
import {
  Box,
  Left,
  Right,
  Container,
  Title,
  BoxAllInvoices,
  YearTitle,
  Invoice,
  BoxInvoiceRight,
  BoxInvoicesPending,
  Skeleton,
  SkeletonGreen,
  BoxPrice,
  BoxPriceMobile,
  BoxInfoDownload,
  BoxInfoCopy,
  InvoiceInfoTitle,
  InvoiceInfoText,
  InvoiceButton,
  InvoiceV2,
  InvoiceRightExpandedText,
  InvoiceRightExpanded,
  BoxInvoicePaymentButton,
  BoxInvoicePaymentContainer,
} from './styles'
import { api } from '../../services/api'
import { FaturaDTO, InvoiceDTO, StateInitial, User } from '../../utils/models'
import { PaymentSlip } from '../../components/PDF'
import { isAuthenticated } from '../../utils/auth'
import Footer from '../../components/Footer'
import SelectFilter from './components/SelectFilter'
import SelectFilterMobile from './components/SelectFilterMobile'
import Notification from './components/Notification'
import CopyIcon from '../../assets/files/copy.svg'
import CopyWhiteIcon from '../../assets/files/copy_white.svg'
import DownloadIcon from '../../assets/files/file_download.svg'
import CheckIcon from '../../assets/files/check.svg'
import InfoBlackIcon from '../../assets/files/info_black.svg'
import InfoGrayIcon from '../../assets/files/info_gray.svg'
import InforCircleIcon from '../../assets/files/info-circle-fill.svg'
import ExpandMoreIcon from '../../assets/files/expand_more.svg'
import RemoveCircle from '../../assets/files/remove_circle.svg'
import ModalMandatoryPayment, {
  ModalPrioridade,
} from '../../components/ModalMandatoryPayment'
import sort, { sortByDate } from '../../utils/order'
import ModalAnalise from '../../components/ModalAnalise'

const DECIMAL_NUMBER = 10
const THREE_SECONDS = 3000

interface DataDashDTO {
  modules: StateInitial
  dispatch: any
}

const Home = ({ modules, dispatch }: DataDashDTO): any => {
  const location = useLocation<any>()
  const [invoices, setInvoices] = useState<InvoiceDTO[]>()
  const [invoicesMandatory, setInvoicesMandatory] = useState<InvoiceDTO[]>()
  const [yearsInvoices, setYears] = useState<Array<number>>([])
  const [invoicesId, setInvoicesId] = useState<number[]>()
  const [loadingData, setLoadingData] = useState(true)
  const [donwloadIdInvoice, setIdInvoice] = useState<string>()
  const [showDetails, setShowDetails] = useState<string>()
  const history = useHistory()
  const [totalCart, setTotalCart] = useState(0)
  const [originalValue, setOriginalValue] = useState(0)
  const [penalty, setPenalty] = useState(0)
  const [fees, setFees] = useState(0)
  const [correction, setCorrection] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showInfo, setShowInfo] = useState<number | null>()
  const [markAll, setMarkAll] = useState(false)
  const [downloadThis, setDownloadThis] = useState<InvoiceDTO>()
  const [showInfoDownloadFile, setShowInfoDownloadFile] = useState<boolean>(
    false
  )

  const [filterInvoices, setFilterInvoices] = useState<'1' | '0' | null>('1')
  const [myOpenInvoices, setMyOpenInvoices] = useState(0)
  const [otherOpenInvoices, setOtherOpenInvoices] = useState(0)
  const [allOpenInvoices, setAllOpenInvoices] = useState(0)
  const [barCodeCopy, setBarCodeCopy] = useState<number | undefined>()

  const [limited, setLimited] = useState(true)

  const [modalObrigatorio, setModalObrigatorio] = useState(false)
  const [modalAnalise, setModalAnalise] = useState(false)
  const [modalPrioridade, setModalPrioridade] = useState(false)
  const [markOld, setMarkOld] = useState(false)

  const [osCorte, setOsCorte] = useState(false)

  const [shake, setShake] = useState<number | undefined>()

  useEffect(() => {
    if (shake !== undefined) {
      setTimeout(() => {
        setShake(undefined)
      }, 1000)
    }
  }, [shake])

  function handleFilter(fill: '1' | '0' | null): void {
    setFilterInvoices(fill)
  }

  // Carrega faturas pendentes de cada cliente e coloca a qtd dentro de const

  useEffect(() => {
    if (invoices) {
      const f = invoices.find((i) => i.OS_CORTE === 'S')
      if (f) {
        setOsCorte(true)
      }
    }
  }, [invoices])

  useEffect(() => {
    // setLimited(modules.LIMITED)

    const infoPermit = sessionStorage.getItem('infoPermission') === 'true'

    setLimited(!infoPermit)
  }, [])

  useEffect(() => {
    let varMyOpenInv = 0
    let varOtherOpenInv = 0
    const temp = localStorage.getItem('invoices')
    if (temp) {
      const tempInvoices = JSON.parse(temp)
      if (tempInvoices)
        tempInvoices?.map((inv: InvoiceDTO) => {
          if (
            inv.FLAG_CLIENTE_ATUAL === '1' &&
            ['ABER', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT) &&
            getSituation(inv) !== 6
          ) {
            varMyOpenInv += 1
          } else if (
            inv.FLAG_CLIENTE_ATUAL === '0' &&
            ['ABER', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT) &&
            getSituation(inv) !== 6
          ) {
            varOtherOpenInv += 1
          }
        })
      setMyOpenInvoices(varMyOpenInv)
      setOtherOpenInvoices(varOtherOpenInv)
      setAllOpenInvoices(varOtherOpenInv + varMyOpenInv)
    }
  }, [invoices, loadingData])

  // checa inativa
  function checkIfInative(inv: InvoiceDTO): boolean {
    if (inv.FORMA_PAGAMENTO === 'I' && inv.FLAG_CLIENTE_ATUAL === '1') {
      return true
    }

    return false
  }

  // checa mandatorio
  function checkIfMandatory(inv: InvoiceDTO): boolean {
    if (
      inv.FORMA_PAGAMENTO === 'O' &&
      getSituation(inv) !== 4 &&
      getSituation(inv) !== 5 &&
      inv.FLAG_CLIENTE_ATUAL === '1' &&
      !getProc(inv) &&
      ['ABER', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT)
    ) {
      return true
    }

    return false
  }

  useEffect(() => {
    setPenalty(0)
    let penaltTemp = 0
    setFees(0)
    let feesTemp = 0
    setCorrection(0)
    let correctionTemp = 0
    setOriginalValue(0)
    let originalValueTemp = 0
    setTotalCart(0)
    let totalCartTemp = 0
    const invoicesIdTemp = [] as number[]

    const invoicesMand = invoices?.filter((inv) => checkIfMandatory(inv))

    invoicesMand?.forEach((invMand) => {
      penaltTemp += parseFloat(invMand.C1_MULTA)
      feesTemp += parseFloat(invMand.C1_JUROS)
      correctionTemp += parseFloat(invMand.C1_CORRECAO)
      originalValueTemp += parseFloat(invMand.TOTAL_FATURA)
      totalCartTemp += parseFloat(invMand.C1_TOTAL_CORRIGIDO)
      if (invMand.FATURA_ID) {
        invoicesIdTemp.push(invMand.FATURA_ID)
      }
    })

    if (invoicesIdTemp.length > 0) {
      setModalObrigatorio(true)
    }

    const invoicesInat = invoices?.find((ii) => checkIfInative(ii))

    if (invoicesInat) {
      // console.log(invoicesInat)
      setModalAnalise(true)
    }

    setPenalty(penaltTemp)
    setFees(feesTemp)
    setCorrection(correctionTemp)
    setOriginalValue(originalValueTemp)
    setTotalCart(totalCartTemp)
    setInvoicesId(invoicesIdTemp)
  }, [invoices])

  // Checa se a data recebida é maior que a data atual
  function checkDueDate(dataInvoice: string): boolean {
    const [day, month, year] = dataInvoice.split('/')
    const dateInvoice = new Date(
      parseInt(year, DECIMAL_NUMBER),
      parseInt(month, DECIMAL_NUMBER) - 1,
      parseInt(day, DECIMAL_NUMBER)
    )
    const newDate = new Date()
    dateInvoice.setHours(23, 59, 59, 999)

    return isAfter(dateInvoice, newDate)
  }

  // Mostra o modal quando os dados do Cliente estão incompletos
  function showModalWhenDocEmpty(
    module: User,
    showModalWhenEmptyDoc: boolean
  ): unknown {
    return {
      type: 'SET_VISIBILITY_MODAL',
      module,
      showModalWhenEmptyDoc,
    }
  }

  // Mostra uma barra de carregamento quando faz consulta no banco
  function setLoadingSearchDb(
    module: boolean,
    loadingSearchDB: boolean
  ): unknown {
    return {
      type: 'SET_LOADINGDB',
      module,
      showLoading: loadingSearchDB,
    }
  }

  // Quando seleciona todas as faturas pendentes que estão sendo mostradas
  useEffect(() => {
    if (invoices) {
      const qtdInvoicesPending = invoices.filter(
        (inv) =>
          ['ABER', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT) &&
          getSituation(inv) !== 6 &&
          !getAnalise(inv) &&
          !getProc(inv)
      ).length
      if (invoicesId && qtdInvoicesPending) {
        if (invoicesId.length < qtdInvoicesPending) {
          setMarkAll(false)
        } else {
          setMarkAll(true)
        }
      }
    }
  }, [invoices, invoicesId])

  // Testa se é a primeira vez na página atual após o Login
  useEffect(() => {
    const first = localStorage.getItem('first_time')
    if (!modules.DATA_CLIENT.NUMERO_DOCTO_1 && first) {
      setTimeout(() => {
        dispatch(showModalWhenDocEmpty(modules.DATA_CLIENT, true))
      }, 3000)
    }
  }, [])

  // Seta a lista dos Anos em que existem faturas
  useEffect(() => {
    if (invoices) {
      const data = []

      if (invoices?.length > 0) {
        data.push(parseFloat(invoices[0].DT_REF_EDT.substr(3)))
        for (let i = 1; i < invoices.length; i += 1) {
          if (
            parseFloat(invoices[i].DT_REF_EDT.substr(3)) !==
            parseFloat(invoices[i - 1].DT_REF_EDT.substr(3))
          ) {
            // data.push(parseFloat(invoices[i].DT_REF_EDT.substr(3)))
          }

          const f = data.find(
            (d) => d === parseFloat(invoices[i].DT_REF_EDT.substr(3))
          )

          if (f === undefined) {
            data.push(parseFloat(invoices[i].DT_REF_EDT.substr(3)))
          }
        }

        // eslint-disable-next-line func-names
        setYears(
          data.sort(function (a, b) {
            return b - a
          })
        )
      }
    }
  }, [invoices])

  // Seta os juros, multas e correções das faturas quando retorna da página de pagamento
  useEffect(() => {
    if (location.state) {
      const tempId = location.state.data.map((fatura: FaturaDTO) => {
        return fatura.id
      })
      let cartTotalTemp = 0
      let originalTemp = 0
      let penaltyTemp = 0
      let feesTemp = 0
      let correctionTemp = 0
      location.state.data.forEach((fatura: FaturaDTO) => {
        penaltyTemp += fatura.multa
        feesTemp += fatura.juros
        correctionTemp += fatura.correcao
        cartTotalTemp += fatura.total
        originalTemp += fatura.fatura
      })
      setPenalty(penaltyTemp)
      setFees(feesTemp)
      setCorrection(correctionTemp)
      setOriginalValue(originalTemp)
      setTotalCart(cartTotalTemp)
      setInvoicesId(tempId)
    }
  }, [location, invoices])

  // Seta as faturas para serem mostradas partindo das salvas no LocalStorage
  useEffect(() => {
    if (isAuthenticated()) {
      const temp = localStorage.getItem('invoices')
      if (temp) {
        const tempInvoices = JSON.parse(temp)
        if (tempInvoices.length > 0 && tempInvoices[0].IDENT_UC) {
          dispatch(setLoadingSearchDb(modules.SEARCH_DB, true))
          setLoadingData(false)
          searchDb()
        } else {
          setYears([])
          searchDb()
        }
      } else {
        setYears([])
        searchDb()
      }
    } else {
      history.push('/login')
    }
  }, [])

  // Ativação dos filtros
  useEffect(() => {
    if (isAuthenticated()) {
      const temp = localStorage.getItem('invoices')
      if (temp) {
        const tempInvoices = JSON.parse(temp)
        if (filterInvoices !== null) {
          if (tempInvoices) {
            const tempInv = tempInvoices.filter(
              (inv: InvoiceDTO) => inv.FLAG_CLIENTE_ATUAL === filterInvoices
            )

            const invo = sortByDate(tempInv, 'DT_REF', false)
            // console.log(invo,'invoDate')
            const mandInv = invo.filter((ti: InvoiceDTO) =>
              checkIfMandatory(ti)
            )

            setInvoicesMandatory(mandInv)
            setInvoices(invo)
          }
        } else {
          // console.log(tempInvoices)
          const invo = sortByDate(tempInvoices, 'DT_REF', false)
          const mandInv = invo.filter((ti: InvoiceDTO) => checkIfMandatory(ti))

          setInvoicesMandatory(mandInv)
          setInvoices(invo)
        }
      }
    }
  }, [filterInvoices, loadingData])

  // Faz a busca no banco e salva no LocalStorage
  async function searchDb(): Promise<void> {
    const token = sessionStorage.getItem('token')
    const dataClient = localStorage.getItem('info')
    try {
      if (token && dataClient) {
        const { IDENT_UC } = JSON.parse(dataClient) as User
        const { data } = await api.get(`${IDENT_UC}/consumes`, {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        })
        localStorage.setItem('invoices', JSON.stringify(data))
      }
      setLoadingData(false)
      dispatch(setLoadingSearchDb(modules.SEARCH_DB, false))
    } catch (error) {
      history.push('/login')
    }
  }

  // Coloca o código de barras no Ctrl+V e mostra o aviso
  function copyBarCode(inv: InvoiceDTO): void {
    if (inv.FATURA_ID) {
      setShowInfo(inv.FATURA_ID)
      setBarCodeCopy(inv.FATURA_ID)
    }
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = inv.BARCODE_LINE
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setTimeout(() => {
      setShowInfo(undefined)
      setBarCodeCopy(undefined)
    }, THREE_SECONDS)
  }

  // Seta os juros, multas e correções quando todas as faturas sao selecionadas
  function checkAllInvoices(checkbox: boolean): void {
    if (checkbox) {
      setPenalty(0)
      let penaltTemp = 0
      setFees(0)
      let feesTemp = 0
      setCorrection(0)
      let correctionTemp = 0
      setOriginalValue(0)
      let originalValueTemp = 0
      setTotalCart(0)
      let totalCartTemp = 0
      setInvoicesId([])
      const invoicesIdTemp = [] as number[]
      const invoicesPending = invoices?.filter(
        (inv) =>
          ['ABER', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT) &&
          getSituation(inv) !== 6 &&
          !getAnalise(inv) &&
          !getProc(inv)
      )
      if (invoicesPending) {
        invoicesPending.forEach((invPend) => {
          penaltTemp += parseFloat(invPend.C1_MULTA)
          feesTemp += parseFloat(invPend.C1_JUROS)
          correctionTemp += parseFloat(invPend.C1_CORRECAO)
          originalValueTemp += parseFloat(invPend.TOTAL_FATURA)
          totalCartTemp += parseFloat(invPend.C1_TOTAL_CORRIGIDO)
          if (invPend.FATURA_ID) {
            invoicesIdTemp.push(invPend.FATURA_ID)
          }
        })
      }
      setPenalty(penaltTemp)
      setFees(feesTemp)
      setCorrection(correctionTemp)
      setOriginalValue(originalValueTemp)
      setTotalCart(totalCartTemp)
      setInvoicesId(invoicesIdTemp)
    } else {
      setPenalty(0)
      let penaltTemp = 0
      setFees(0)
      let feesTemp = 0
      setCorrection(0)
      let correctionTemp = 0
      setOriginalValue(0)
      let originalValueTemp = 0
      setTotalCart(0)
      let totalCartTemp = 0
      const invoicesIdTemp = [] as number[]

      if (osCorte) {
        const invoicesMand = invoices?.filter((inv) => checkIfMandatory(inv))

        invoicesMand?.forEach((invMand) => {
          penaltTemp += parseFloat(invMand.C1_MULTA)
          feesTemp += parseFloat(invMand.C1_JUROS)
          correctionTemp += parseFloat(invMand.C1_CORRECAO)
          originalValueTemp += parseFloat(invMand.TOTAL_FATURA)
          totalCartTemp += parseFloat(invMand.C1_TOTAL_CORRIGIDO)
          if (invMand.FATURA_ID) {
            invoicesIdTemp.push(invMand.FATURA_ID)
          }
        })
      }
      setPenalty(penaltTemp)
      setFees(feesTemp)
      setCorrection(correctionTemp)
      setOriginalValue(originalValueTemp)
      setTotalCart(totalCartTemp)
      setInvoicesId(invoicesIdTemp)
    }
  }

  // pega numero de invoices limitadas

  function getLimitedInvoicesNumber(): number {
    let counter = 0
    if (!limited) {
      counter = invoices?.length || 0
    } else
      invoices?.forEach((i) => {
        if (i.SIT_FATURA_EDT !== 'PAGA' && i.SIT_FATURA_EDT !== 'PAGR') {
          counter += 1
        }
      })

    return counter
  }

  function startShake(b: boolean): void {
    console.log(b)
    if (!b && invoicesMandatory) {
      let i = 0
      i = invoicesMandatory?.findIndex((im) => checkIdList(im.FATURA_ID)) || -1
      console.log(i)
      if (i !== undefined && i !== -1) {
        // console.log(i)
        if (i !== 0) {
          setShake(invoicesMandatory[i].FATURA_ID || 0)
        }
      } else if (i === -1) {
        setShake(invoicesMandatory[0].FATURA_ID || 0)
      }
    } else if (b && invoicesMandatory) {
      let i = 0
      i = invoicesMandatory?.findIndex((im) => checkIdList(im.FATURA_ID)) || -1
      console.log(i)
      if (i === -1) {
        setShake(invoicesMandatory[invoicesMandatory.length - 1].FATURA_ID || 0)
      } else {
        setShake(invoicesMandatory[i - 1].FATURA_ID || 0)
      }

      console.log(invoicesMandatory)
    }
  }

  function handlePriorityModalOld(b: boolean): void {
    setMarkOld(b)

    setModalPrioridade(true)

    // console.log(invoicesMandatory)
  }

  // Seta os juros, multas e correções da fatura selcionada
  function handlePaymentButton(invoice: InvoiceDTO): void {
    if (!checkIfMandatory(invoice)) {
      const resp = invoicesId?.filter((inv) => {
        return inv !== invoice.FATURA_ID
      })
      if (resp) {
        if (resp?.length === invoicesId?.length) {
          setOriginalValue(originalValue + parseFloat(invoice.TOTAL_FATURA))
          setPenalty(penalty + parseFloat(invoice.C1_MULTA))
          setFees(fees + parseFloat(invoice.C1_JUROS))
          setCorrection(correction + parseFloat(invoice.C1_CORRECAO))
          setTotalCart(totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO))
          if (invoice.FATURA_ID)
            setInvoicesId([...invoicesId, invoice.FATURA_ID])
        } else {
          if (totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <= 1) {
            setPenalty(0)
            setFees(0)
            setCorrection(0)
            setOriginalValue(0)
            setTotalCart(0)
          } else {
            setOriginalValue(originalValue - parseFloat(invoice.TOTAL_FATURA))
            setPenalty(penalty - parseFloat(invoice.C1_MULTA))
            setFees(fees - parseFloat(invoice.C1_JUROS))
            setCorrection(correction - parseFloat(invoice.C1_CORRECAO))
            setTotalCart(totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO))
          }
          setInvoicesId(resp)
        }
      } else {
        setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
        setPenalty(parseFloat(invoice.C1_MULTA))
        setFees(parseFloat(invoice.C1_JUROS))
        setCorrection(parseFloat(invoice.C1_CORRECAO))
        setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
        if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
      }
    } else if (!osCorte) {
      const index = invoicesMandatory?.findIndex(
        (i) => i.FATURA_ID === invoice.FATURA_ID
      )
      if (invoicesMandatory)
        if (index !== undefined)
          if (index !== -1) {
            if (index === 0) {
              if (checkIdList(invoice.FATURA_ID)) {
                const resp = invoicesId?.filter((inv) => {
                  return inv !== invoice.FATURA_ID
                })

                if (resp) {
                  if (resp?.length === invoicesId?.length) {
                    setOriginalValue(
                      originalValue + parseFloat(invoice.TOTAL_FATURA)
                    )
                    setPenalty(penalty + parseFloat(invoice.C1_MULTA))
                    setFees(fees + parseFloat(invoice.C1_JUROS))
                    setCorrection(correction + parseFloat(invoice.C1_CORRECAO))
                    setTotalCart(
                      totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                    )
                    if (invoice.FATURA_ID)
                      setInvoicesId([...invoicesId, invoice.FATURA_ID])
                  } else {
                    if (
                      totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <=
                      1
                    ) {
                      setPenalty(0)
                      setFees(0)
                      setCorrection(0)
                      setOriginalValue(0)
                      setTotalCart(0)
                    } else {
                      setOriginalValue(
                        originalValue - parseFloat(invoice.TOTAL_FATURA)
                      )
                      setPenalty(penalty - parseFloat(invoice.C1_MULTA))
                      setFees(fees - parseFloat(invoice.C1_JUROS))
                      setCorrection(
                        correction - parseFloat(invoice.C1_CORRECAO)
                      )
                      setTotalCart(
                        totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                      )
                    }
                    setInvoicesId(resp)
                  }
                } else {
                  setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
                  setPenalty(parseFloat(invoice.C1_MULTA))
                  setFees(parseFloat(invoice.C1_JUROS))
                  setCorrection(parseFloat(invoice.C1_CORRECAO))
                  setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
                  if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
                }
              } else if (invoicesMandatory.length > index + 1) {
                // console.log(checkIdList(invoicesMandatory[index + 1].FATURA_ID),invoicesMandatory[index+1].FATURA_ID,invoicesMandatory)

                if (checkIdList(invoicesMandatory[index + 1].FATURA_ID)) {
                  const resp = invoicesId?.filter((inv) => {
                    return inv !== invoice.FATURA_ID
                  })
                  if (resp) {
                    if (resp?.length === invoicesId?.length) {
                      setOriginalValue(
                        originalValue + parseFloat(invoice.TOTAL_FATURA)
                      )
                      setPenalty(penalty + parseFloat(invoice.C1_MULTA))
                      setFees(fees + parseFloat(invoice.C1_JUROS))
                      setCorrection(
                        correction + parseFloat(invoice.C1_CORRECAO)
                      )
                      setTotalCart(
                        totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                      )
                      if (invoice.FATURA_ID)
                        setInvoicesId([...invoicesId, invoice.FATURA_ID])
                    } else {
                      if (
                        totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <=
                        1
                      ) {
                        setPenalty(0)
                        setFees(0)
                        setCorrection(0)
                        setOriginalValue(0)
                        setTotalCart(0)
                      } else {
                        setOriginalValue(
                          originalValue - parseFloat(invoice.TOTAL_FATURA)
                        )
                        setPenalty(penalty - parseFloat(invoice.C1_MULTA))
                        setFees(fees - parseFloat(invoice.C1_JUROS))
                        setCorrection(
                          correction - parseFloat(invoice.C1_CORRECAO)
                        )
                        setTotalCart(
                          totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                        )
                      }
                      setInvoicesId(resp)
                    }
                  } else {
                    setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
                    setPenalty(parseFloat(invoice.C1_MULTA))
                    setFees(parseFloat(invoice.C1_JUROS))
                    setCorrection(parseFloat(invoice.C1_CORRECAO))
                    setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
                    if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
                  }
                } else {
                  handlePriorityModalOld(true)
                }
              } else if (invoicesMandatory.length === 1 && index === 0) {
                const resp = invoicesId?.filter((inv) => {
                  return inv !== invoice.FATURA_ID
                })
                if (resp) {
                  if (resp?.length === invoicesId?.length) {
                    setOriginalValue(
                      originalValue + parseFloat(invoice.TOTAL_FATURA)
                    )
                    setPenalty(penalty + parseFloat(invoice.C1_MULTA))
                    setFees(fees + parseFloat(invoice.C1_JUROS))
                    setCorrection(correction + parseFloat(invoice.C1_CORRECAO))
                    setTotalCart(
                      totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                    )
                    if (invoice.FATURA_ID)
                      setInvoicesId([...invoicesId, invoice.FATURA_ID])
                  } else {
                    if (
                      totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <=
                      1
                    ) {
                      setPenalty(0)
                      setFees(0)
                      setCorrection(0)
                      setOriginalValue(0)
                      setTotalCart(0)
                    } else {
                      setOriginalValue(
                        originalValue - parseFloat(invoice.TOTAL_FATURA)
                      )
                      setPenalty(penalty - parseFloat(invoice.C1_MULTA))
                      setFees(fees - parseFloat(invoice.C1_JUROS))
                      setCorrection(
                        correction - parseFloat(invoice.C1_CORRECAO)
                      )
                      setTotalCart(
                        totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                      )
                    }
                    setInvoicesId(resp)
                  }
                } else {
                  setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
                  setPenalty(parseFloat(invoice.C1_MULTA))
                  setFees(parseFloat(invoice.C1_JUROS))
                  setCorrection(parseFloat(invoice.C1_CORRECAO))
                  setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
                  if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
                }
              }
            } else if (checkIdList(invoice.FATURA_ID)) {
              if (!checkIdList(invoicesMandatory[index - 1].FATURA_ID)) {
                const resp = invoicesId?.filter((inv) => {
                  return inv !== invoice.FATURA_ID
                })
                if (resp) {
                  if (resp?.length === invoicesId?.length) {
                    setOriginalValue(
                      originalValue + parseFloat(invoice.TOTAL_FATURA)
                    )
                    setPenalty(penalty + parseFloat(invoice.C1_MULTA))
                    setFees(fees + parseFloat(invoice.C1_JUROS))
                    setCorrection(correction + parseFloat(invoice.C1_CORRECAO))
                    setTotalCart(
                      totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                    )
                    if (invoice.FATURA_ID)
                      setInvoicesId([...invoicesId, invoice.FATURA_ID])
                  } else {
                    if (
                      totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <=
                      1
                    ) {
                      setPenalty(0)
                      setFees(0)
                      setCorrection(0)
                      setOriginalValue(0)
                      setTotalCart(0)
                    } else {
                      setOriginalValue(
                        originalValue - parseFloat(invoice.TOTAL_FATURA)
                      )
                      setPenalty(penalty - parseFloat(invoice.C1_MULTA))
                      setFees(fees - parseFloat(invoice.C1_JUROS))
                      setCorrection(
                        correction - parseFloat(invoice.C1_CORRECAO)
                      )
                      setTotalCart(
                        totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                      )
                    }
                    setInvoicesId(resp)
                  }
                } else {
                  setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
                  setPenalty(parseFloat(invoice.C1_MULTA))
                  setFees(parseFloat(invoice.C1_JUROS))
                  setCorrection(parseFloat(invoice.C1_CORRECAO))
                  setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
                  if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
                }
              } else {
                handlePriorityModalOld(false)
              }
            } else if (invoicesMandatory.length > index + 1) {
              if (checkIdList(invoicesMandatory[index + 1].FATURA_ID)) {
                const resp = invoicesId?.filter((inv) => {
                  return inv !== invoice.FATURA_ID
                })
                if (resp) {
                  if (resp?.length === invoicesId?.length) {
                    setOriginalValue(
                      originalValue + parseFloat(invoice.TOTAL_FATURA)
                    )
                    setPenalty(penalty + parseFloat(invoice.C1_MULTA))
                    setFees(fees + parseFloat(invoice.C1_JUROS))
                    setCorrection(correction + parseFloat(invoice.C1_CORRECAO))
                    setTotalCart(
                      totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                    )
                    if (invoice.FATURA_ID)
                      setInvoicesId([...invoicesId, invoice.FATURA_ID])
                  } else {
                    if (
                      totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <=
                      1
                    ) {
                      setPenalty(0)
                      setFees(0)
                      setCorrection(0)
                      setOriginalValue(0)
                      setTotalCart(0)
                    } else {
                      setOriginalValue(
                        originalValue - parseFloat(invoice.TOTAL_FATURA)
                      )
                      setPenalty(penalty - parseFloat(invoice.C1_MULTA))
                      setFees(fees - parseFloat(invoice.C1_JUROS))
                      setCorrection(
                        correction - parseFloat(invoice.C1_CORRECAO)
                      )
                      setTotalCart(
                        totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                      )
                    }
                    setInvoicesId(resp)
                  }
                } else {
                  setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
                  setPenalty(parseFloat(invoice.C1_MULTA))
                  setFees(parseFloat(invoice.C1_JUROS))
                  setCorrection(parseFloat(invoice.C1_CORRECAO))
                  setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
                  if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
                }
              } else {
                handlePriorityModalOld(true)
              }
            } else {
              const resp = invoicesId?.filter((inv) => {
                return inv !== invoice.FATURA_ID
              })
              if (resp) {
                if (resp?.length === invoicesId?.length) {
                  setOriginalValue(
                    originalValue + parseFloat(invoice.TOTAL_FATURA)
                  )
                  setPenalty(penalty + parseFloat(invoice.C1_MULTA))
                  setFees(fees + parseFloat(invoice.C1_JUROS))
                  setCorrection(correction + parseFloat(invoice.C1_CORRECAO))
                  setTotalCart(
                    totalCart + parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                  )
                  if (invoice.FATURA_ID)
                    setInvoicesId([...invoicesId, invoice.FATURA_ID])
                } else {
                  if (totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO) <= 1) {
                    setPenalty(0)
                    setFees(0)
                    setCorrection(0)
                    setOriginalValue(0)
                    setTotalCart(0)
                  } else {
                    setOriginalValue(
                      originalValue - parseFloat(invoice.TOTAL_FATURA)
                    )
                    setPenalty(penalty - parseFloat(invoice.C1_MULTA))
                    setFees(fees - parseFloat(invoice.C1_JUROS))
                    setCorrection(correction - parseFloat(invoice.C1_CORRECAO))
                    setTotalCart(
                      totalCart - parseFloat(invoice.C1_TOTAL_CORRIGIDO)
                    )
                  }
                  setInvoicesId(resp)
                }
              } else {
                setOriginalValue(parseFloat(invoice.TOTAL_FATURA))
                setPenalty(parseFloat(invoice.C1_MULTA))
                setFees(parseFloat(invoice.C1_JUROS))
                setCorrection(parseFloat(invoice.C1_CORRECAO))
                setTotalCart(parseFloat(invoice.C1_TOTAL_CORRIGIDO))
                if (invoice.FATURA_ID) setInvoicesId([invoice.FATURA_ID])
              }
            }
          }
    }
  }

  // Testa se o ID recebido está selecionado
  function checkIdList(invoiceIdparse: number | null): boolean {
    if (invoiceIdparse) {
      const resp = invoicesId?.filter((invoice) => {
        return invoice === invoiceIdparse
      })
      if (resp)
        if (resp?.length > 0) {
          return true
        }
    }
    return false
  }

  // Gera os textos dos Labels
  function generatingTextStatus(inv: InvoiceDTO): string {
    if (
      !checkDueDate(inv.DT_VENCIMENTO_EDT) &&
      (getSituation(inv) === 2 || getSituation(inv) === 6)
    ) {
      return 'VENCIDA'
    }
    return getTextSituation(inv)
  }

  // Gera as cores dos Labels

  function checkIfPaid(inv: InvoiceDTO): boolean {
    if (
      !checkDueDate(inv.DT_VENCIMENTO_EDT) &&
      (getSituation(inv) === 2 || getSituation(inv) === 6)
    ) {
      return false
    }

    const lista = [
      { id: 'TRAN', result: false },
      { id: 'ABER', result: false },
      { id: 'REAV', result: false },
      { id: 'PAGR', result: true },
      { id: 'PAGA', result: true },
      { id: 'PROC', result: true },
      { id: 'CONF', result: true },
    ]

    const find = lista.find((l) => l.id === inv.SIT_FATURA_EDT)
    if (find) {
      return find.result
    }

    return false
  }

  function generateColorStatus(inv: InvoiceDTO): string {
    if (
      !checkDueDate(inv.DT_VENCIMENTO_EDT) &&
      (getSituation(inv) === 2 || getSituation(inv) === 6)
    ) {
      return '#DC3545'
    }
    if (
      inv.NUM_LOTE_ARREC !== null &&
      ['TRAN', 'ABER', 'REAV'].includes(inv.SIT_FATURA_EDT)
    ) {
      return 'var(--secondaryColor)'
    }
    const colors = [
      { id: 'TRAN', name: 'var(--faturaVencida)' },
      { id: 'ABER', name: 'var(--primaryColorLight)' },
      { id: 'REAV', name: 'var(--primaryColorLight)' },
      { id: 'PAGR', name: 'var(--faturaPaga)' },
      { id: 'PAGA', name: 'var(--faturaPaga)' },
      { id: 'PROC', name: 'var(--secondaryColor)' },
      { id: 'CONF', name: 'var(--secondaryColor)' },
    ]
    const respoColor = colors.filter(
      (color) => color.id === inv.SIT_FATURA_EDT
    )[0].name
    if (respoColor) {
      return respoColor
    }
    return 'GRAY'
  }

  function generatingColorStatus(inv: InvoiceDTO): string {
    if (
      !checkDueDate(inv.DT_VENCIMENTO_EDT) &&
      (getSituation(inv) === 2 || getSituation(inv) === 6)
    ) {
      return '#DC3545'
    }
    return getColorSituation(inv)
  }

  // checka se invoice esta em analise
  function getAnalise(invoiceItem: InvoiceDTO): boolean {
    return invoiceItem.FORMA_PAGAMENTO === 'I'
  }

  // checka se invoice esta processando
  function getProc(invoiceItem: InvoiceDTO): boolean {
    return (
      invoiceItem.SIT_FATURA_EDT === 'PROC' ||
      invoiceItem.SIT_FATURA_EDT === 'CONF' ||
      getTextSituation(invoiceItem) === 'PROCESSANDO'
    )
  }

  // Seta o texto da situação da Fatura
  function getTextSituation(situation: InvoiceDTO): string {
    if (
      situation.NUM_LOTE_ARREC !== null &&
      ['TRAN', 'ABER', 'REAV'].includes(situation.SIT_FATURA_EDT)
    ) {
      return 'PROCESSANDO'
    }
    const situationText = [
      { nome: 'TRAN', text: 'VENCIDA' },
      { nome: 'ABER', text: 'A VENCER' },
      { nome: 'REAV', text: 'A VENCER' },
      { nome: 'PAGR', text: 'PAGA' },
      { nome: 'PAGA', text: 'PAGA' },
      { nome: 'PROC', text: 'APROVADO' },
      { nome: 'CONF', text: 'APROVADO' },
    ]
    const respoSituation = situationText.filter(
      (text) => text.nome === situation.SIT_FATURA_EDT
    )[0].text
    if (respoSituation) {
      return respoSituation
    }
    return 'SITUAÇÃO'
  }

  // Seta a cor da situação da Fatura
  function getColorSituation(situation: InvoiceDTO): string {
    if (
      situation.NUM_LOTE_ARREC !== null &&
      ['TRAN', 'ABER', 'REAV'].includes(situation.SIT_FATURA_EDT)
    ) {
      return 'var(--secondaryColor)'
    }
    const colors = [
      { id: 'TRAN', name: 'var(--faturaVencida)' },
      { id: 'ABER', name: 'var(--primaryColorLight)' },
      { id: 'REAV', name: 'var(--primaryColorLight)' },
      { id: 'PAGR', name: 'var(--primaryColor)' },
      { id: 'PAGA', name: 'var(--primaryColor)' },
      { id: 'PROC', name: 'var(--secondaryColor)' },
      { id: 'CONF', name: 'var(--secondaryColor)' },
    ]
    const respoColor = colors.filter(
      (color) => color.id === situation.SIT_FATURA_EDT
    )[0].name
    if (respoColor) {
      return respoColor
    }
    return 'GRAY'
  }

  // Seta o mês de Referencia abreviado da Fatura
  function getMonthInvoice(month: string): string {
    const months = [
      { id: '01', name: 'Janeiro' },
      { id: '02', name: 'Fevereiro' },
      { id: '03', name: 'Março' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Maio' },
      { id: '06', name: 'Junho' },
      { id: '07', name: 'Julho' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Setembro' },
      { id: '10', name: 'Outubro' },
      { id: '11', name: 'Novembro' },
      { id: '12', name: 'Dezembro' },
    ]
    const resultMonth = months.filter((monthObj) => monthObj.id === month)[0]
      .name
    if (resultMonth) {
      return resultMonth
    }
    return 'MÊS'
  }

  // Seta a situação das Faturas
  function getSituation(situation: InvoiceDTO): number {
    if (
      situation.NUM_LOTE_ARREC !== null &&
      ['TRAN', 'ABER', 'REAV'].includes(situation.SIT_FATURA_EDT)
    ) {
      return 5
    }
    if (
      situation.CAUC_061_DT_FIM_VIGENCIA !== null &&
      ['TRAN', 'ABER', 'REAV'].includes(situation.SIT_FATURA_EDT)
    ) {
      if (situation.CAUC_061_DT_FIM_VIGENCIA.length < 10) {
        const dateConf = `${situation.CAUC_061_DT_FIM_VIGENCIA.substr(
          0,
          6
        )}20${situation.CAUC_061_DT_FIM_VIGENCIA.substr(6, 8)}`
        if (checkDueDate(dateConf)) {
          return 6
        }
      } else if (situation.CAUC_061_DT_FIM_VIGENCIA.length === 10) {
        if (checkDueDate(situation.CAUC_061_DT_FIM_VIGENCIA)) {
          return 6
        }
      }
    }

    const situationsInvoices = [
      { id: 1, name: 'TRAN', type: 2 },
      { id: 2, name: 'ABER', type: 2 },
      { id: 3, name: 'REAV', type: 2 },
      { id: 4, name: 'PAGR', type: 4 },
      { id: 5, name: 'PAGA', type: 4 },
      { id: 6, name: 'PROC', type: 5 },
      { id: 7, name: 'CONF', type: 5 },
    ]
    const respoSituation = situationsInvoices.filter(
      (sit) => sit.name === situation.SIT_FATURA_EDT
    )[0].type
    if (respoSituation) {
      return respoSituation
    }
    return 0
  }

  // Manda as faturas selecionadas para o back e recebe o resumo e manda para a página de payment
  async function handlePay(): Promise<void> {
    setLoading(true)
    const temp = localStorage.getItem('info')
    const token = sessionStorage.getItem('token')
    let cliente: User
    if (temp && token) {
      cliente = JSON.parse(temp)
      try {
        if (invoices) {
          const { data } = await api.post(
            '/pagamento',
            {
              cliente: cliente?.NOME_CLIENTE,
              uc: cliente?.IDENT_UC,
              faturas: invoicesId,
              doc: cliente?.NUMERO_DOCTO_1,
            },
            {
              headers: {
                Authorization: `Basic ${token}`,
                'X-Forwarded-Host':
                  window.location.hostname || process.env.REACT_APP_HOST,
              },
            }
          )
          if (data) {
            setLoading(false)
            history.push({
              pathname: '/payment',
              state: {
                data,
              },
            })
          }
        }
      } catch (error) {
        setLoading(false)
        history.push('/login')
      }
    } else {
      setLoading(false)
      history.push('/login')
    }
  }

  // Testa qual fatura foi clicada para ver os detalhes
  function handleShowDetails(notaFiscal: string): void {
    if (showDetails === notaFiscal) {
      setShowDetails(undefined)
    } else {
      setShowDetails(notaFiscal)
    }
  }

  // Cria, por 3 segundos, a página de PDF para baixar
  function downloadPdf(invoice: InvoiceDTO): void {
    setShowInfoDownloadFile(true)
    setTimeout(() => {
      setDownloadThis(invoice)
      setShowInfoDownloadFile(false)
    }, THREE_SECONDS)
    setDownloadThis(undefined)
    if (donwloadIdInvoice === invoice.NOTA_FISCAL) {
      setIdInvoice(undefined)
    } else {
      setIdInvoice(invoice.NOTA_FISCAL)
    }
  }

  return (
    <Container className="12346">
      <ModalMandatoryPayment
        setModalVisible={setModalObrigatorio}
        modalVisible={modalObrigatorio}
      />
      <ModalAnalise
        setModalVisible={setModalAnalise}
        modalVisible={modalAnalise}
      />

      <ModalPrioridade
        modalVisible={modalPrioridade}
        setModalVisible={setModalPrioridade}
        markOld={markOld}
        startShake={startShake}
      />

      <Notification otherInvoices={otherOpenInvoices} />
      <div style={{ display: 'none', opacity: 0 }}>
        {downloadThis && (
          <PaymentSlip idInvoice={donwloadIdInvoice} invoice={downloadThis} />
        )}
      </div>
      <Box>
        <BoxInfoDownload
          style={{
            opacity: !showInfoDownloadFile ? 0 : 1,
            bottom: !showInfoDownloadFile ? 0 : 40,
            transition: '0.5s ease',
            padding: 0,
          }}
        >
          <Lottie
            style={{ width: 20, height: 20, margin: 0, padding: 0 }}
            options={{
              autoplay: true,
              loop: true,
              animationData: downloadLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isStopped={!showInfoDownloadFile}
            height={20}
            width={20}
          />
          <span>Salvando Fatura</span>
        </BoxInfoDownload>
        <BoxInfoCopy
          style={{
            opacity: !showInfo ? 0 : 1,
            bottom: !showInfo ? 0 : 40,
            transition: 'all 0.5s ease',
            padding: 0,
          }}
        >
          <Lottie
            style={{
              width: 20,
              height: 20,
              margin: '0px 0px 0px 0px',
              padding: 0,
            }}
            options={{
              autoplay: true,
              loop: true,
              animationData: copyLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isStopped={!showInfo}
            height={20}
            width={20}
          />
          <span>Código copiado!</span>
        </BoxInfoCopy>
        <Left>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title style={{ width: '100%' }}>
              {invoices?.filter(
                (inv) =>
                  ['ABER', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT) &&
                  getSituation(inv) !== 6 &&
                  !getAnalise(inv) &&
                  !getProc(inv)
              ).length ? (
                <button
                  type="button"
                  onClick={() => checkAllInvoices(!markAll)}
                  style={{
                    display: 'flex',
                    border: 'none',
                    background: 'transparent',
                  }}
                >
                  <input
                    hidden
                    type="checkbox"
                    id="checkBox"
                    checked={markAll}
                    onChange={(e) => checkAllInvoices(e.target.checked)}
                  />
                  <div
                    style={{
                      transition: '0.2s',
                      border: '2px solid var(--secondaryColor)',
                      height: 24,
                      width: 24,
                      borderRadius: '50%',
                      background: markAll ? 'var(--secondaryColor)' : '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="#fff"
                      style={{ height: 12 }}
                    />
                  </div>
                </button>
              ) : null}
              <SelectFilter
                allInv={allOpenInvoices}
                myInv={myOpenInvoices}
                otherInv={otherOpenInvoices}
                handleFilter={handleFilter}
              />
            </Title>
          </div>
          {!loadingData ? (
            invoices?.filter(
              (inv) =>
                ['ABER', 'CONF', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT) &&
                getSituation(inv) !== 6
            ) &&
            invoices?.filter((inv) =>
              ['ABER', 'CONF', 'REAV', 'TRAN'].includes(inv.SIT_FATURA_EDT)
            ).length > 0 ? (
              <BoxInvoicesPending
                style={{
                  marginBottom: totalCart <= 0 ? 0 : 0,
                  transition: '0.3s',
                }}
              >
                {invoices?.map((invoiceItem) => {
                  if (getSituation(invoiceItem) !== 4) {
                    return (
                      <div
                        id="INVOICE_CONTAINER"
                        className={`${
                          invoiceItem.FATURA_ID === shake ? 'shake_item' : ''
                        } ${
                          checkIdList(invoiceItem.FATURA_ID)
                            ? 'border_selected'
                            : 'border_unselected'
                        }`}
                        key={invoiceItem.NOTA_FISCAL}
                        style={{
                          overflow: 'hidden',
                          height: 180,
                          borderRadius: 16,
                          backgroundColor: '#fff',
                          position: 'relative',
                          maxWidth: 296,
                          maxHeight: 180,
                          top: 0,
                        }}
                      >
                        <BoxInvoiceRight
                          onClick={() => {
                            // e.preventDefault()

                            if (
                              !getAnalise(invoiceItem) &&
                              !getProc(invoiceItem)
                            )
                              handlePaymentButton(invoiceItem)
                          }}
                          id="invoiceBox"
                          style={{ top: 0, transition: '0.5s' }}
                        >
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                              }}
                            >
                              <InvoiceInfoTitle>Fatura</InvoiceInfoTitle>
                              <InvoiceInfoTitle>Valor</InvoiceInfoTitle>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                              }}
                            >
                              <InvoiceInfoText>
                                {`${
                                  getMonthInvoice(
                                    invoiceItem.DT_REF_EDT.substr(0, 2)
                                  ) +
                                  invoiceItem.DT_REF_EDT.substr(2, 6) +
                                  (invoiceItem.FD !== '0'
                                    ? `-${invoiceItem.FD}`
                                    : '')
                                }`}
                              </InvoiceInfoText>
                              <InvoiceInfoText>
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="solid"
                                  id={`InvoiceValueInfo${invoiceItem.FATURA_ID}`}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 5,
                                      alignItems: 'flex-start',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                        color: '#FFFFFF',
                                        fontWeight: 400,
                                      }}
                                    >
                                      M+J+C
                                      {' '}
                                      <span
                                        style={{
                                          fontFamily: 'Roboto',
                                          fontSize: 14,
                                          color: '#FFFFFF',
                                          fontWeight: 500,
                                        }}
                                      >
                                        R$
                                        {(
                                          parseFloat(invoiceItem.C1_MULTA) +
                                          parseFloat(invoiceItem.C1_CORRECAO) +
                                          parseFloat(invoiceItem.C1_JUROS)
                                        ).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </span>
                                    </p>
                                  </div>
                                </ReactTooltip>
                                {parseFloat(invoiceItem.C1_MULTA) +
                                  parseFloat(invoiceItem.C1_CORRECAO) +
                                  parseFloat(invoiceItem.C1_JUROS) >
                                  0 && (
                                  <img
                                    data-tip
                                    data-for={`InvoiceValueInfo${invoiceItem.FATURA_ID}`}
                                    style={{
                                      width: 16,
                                      height: 16,
                                      alignSelf: 'center',
                                    }}
                                    alt="copy"
                                    src={InfoBlackIcon}
                                  />
                                )}
                                {`R$ ${parseFloat(
                                  invoiceItem.C1_TOTAL_CORRIGIDO
                                ).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}`}
                              </InvoiceInfoText>
                            </div>
                          </div>

                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                marginTop: 10,
                              }}
                            >
                              <InvoiceInfoTitle>Cliente</InvoiceInfoTitle>
                              <InvoiceInfoTitle>Vencimento</InvoiceInfoTitle>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                              }}
                            >
                              <InvoiceInfoText>
                                {limited
                                  ? 'Cliente atual'
                                  : invoiceItem.NOME_CLIENTE.length > 15
                                  ? `${invoiceItem.NOME_CLIENTE.substr(
                                      0,
                                      13
                                    )}...`.toLowerCase()
                                  : invoiceItem.NOME_CLIENTE.toLowerCase()}
                              </InvoiceInfoText>
                              <InvoiceInfoText>
                                {invoiceItem.DT_VENCIMENTO_EDT}
                              </InvoiceInfoText>
                            </div>
                          </div>
                          {getAnalise(invoiceItem) ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent:
                                  barCodeCopy === invoiceItem.FATURA_ID
                                    ? 'flex-end'
                                    : 'space-between',

                                flexDirection: 'row',
                                marginTop: 24,
                              }}
                            >
                              <ReactTooltip
                                id="buttonAnaliseInvoice"
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <h4>
                                    Fatura em análise, seu pagamento não pode
                                    ser realizado
                                  </h4>
                                </div>
                              </ReactTooltip>
                              <button
                                data-tip
                                data-for="buttonAnaliseInvoice"
                                type="button"
                                className="invoice_fatura_analise"
                              >
                                <img
                                  src={InfoGrayIcon}
                                  className="mr-6 InfoGrayIcon"
                                  alt="info"
                                />
                                <img
                                  src={InfoBlackIcon}
                                  className="mr-6 InfoBlackIcon"
                                  alt="info"
                                />
                                Fatura em análise
                              </button>
                            </div>
                          ) : getProc(invoiceItem) ? (
                            <div id="pagamento_aprovado_container">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <img alt="info" src={InforCircleIcon} />
                                <span id="pagamento_aprovado_texto">
                                  O pagamento desta fatura foi aprovado e será
                                  validado em até 48h úteis.
                                </span>
                              </div>
                              <ReactTooltip
                                id="buttonTextDownload"
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <h4>Baixar via de pagamento</h4>
                                </div>
                              </ReactTooltip>

                              {!(barCodeCopy === invoiceItem.FATURA_ID) &&
                                !limited && (
                                  <InvoiceButton
                                    data-tip
                                    data-for="buttonTextDownload"
                                    style={{ justifyContent: 'center' }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      downloadPdf(invoiceItem)
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: 16,
                                        height: 16,
                                        alignSelf: 'center',
                                      }}
                                      alt="download"
                                      src={DownloadIcon}
                                    />
                                  </InvoiceButton>
                                )}
                            </div>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent:
                                  barCodeCopy === invoiceItem.FATURA_ID
                                    ? 'flex-end'
                                    : 'space-between',

                                flexDirection: 'row',
                                marginTop: 24,
                              }}
                            >
                              {barCodeCopy ===
                              invoiceItem.FATURA_ID ? null : checkIfMandatory(
                                  invoiceItem
                                ) ? (
                                  <InvoiceButton
                                    style={{
                                    minWidth: 150,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    // justifyContent: 'center',
                                  }}
                                    active={checkIdList(invoiceItem.FATURA_ID)}
                                    onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    handlePaymentButton(invoiceItem)
                                  }}
                                  >
                                    <span>
                                      <img
                                        className="invoice_check_img"
                                        style={{
                                        width: 12,
                                        height: 12,
                                        alignSelf: 'center',
                                      }}
                                        alt="check"
                                        src={CheckIcon}
                                      />
                                    </span>
                                    <p className="invoice_selecionada">
                                      Obrigatório
                                    </p>
                                    <p className="invoice_nao_selecionada">
                                      Selecionar Fatura
                                    </p>
                                  </InvoiceButton>
                              ) : (
                                <InvoiceButton
                                  style={{ minWidth: 150 }}
                                  active={checkIdList(invoiceItem.FATURA_ID)}
                                >
                                  <span>
                                    <img
                                      className="invoice_check_img"
                                      style={{
                                        width: 12,
                                        height: 12,
                                        alignSelf: 'center',
                                      }}
                                      alt="check"
                                      src={CheckIcon}
                                    />
                                  </span>
                                  <p className="invoice_selecionada">
                                    Fatura selecionada
                                  </p>
                                  <p className="invoice_nao_selecionada">
                                    Selecionar Fatura
                                  </p>
                                </InvoiceButton>
                              )}
                              <ReactTooltip
                                id="buttonTextCopy"
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <p
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 14,
                                      color: '#FFFFFF',
                                    }}
                                  >
                                    Copiar código de barras
                                  </p>
                                </div>
                              </ReactTooltip>
                              <InvoiceButton
                                barCodeCopy={
                                  barCodeCopy === invoiceItem.FATURA_ID
                                }
                                data-tip
                                data-for="buttonTextCopy"
                                style={{
                                  justifyContent: 'center',
                                  width:
                                    barCodeCopy === invoiceItem.FATURA_ID
                                      ? '100%'
                                      : 36,
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                                active={
                                  barCodeCopy === invoiceItem.FATURA_ID
                                    ? true
                                    : undefined
                                }
                                onClick={(e) => {
                                  e.stopPropagation()
                                  copyBarCode(invoiceItem)
                                }}
                              >
                                <img
                                  style={{
                                    width: 16,
                                    height: 16,
                                    alignSelf: 'center',
                                  }}
                                  alt="copy"
                                  src={
                                    barCodeCopy === invoiceItem.FATURA_ID
                                      ? CopyWhiteIcon
                                      : CopyIcon
                                  }
                                />
                                {barCodeCopy === invoiceItem.FATURA_ID && (
                                  <p
                                    style={{
                                      fontSize: 13,
                                      color: '#FFFFFF',
                                      marginLeft: 6,
                                    }}
                                  >
                                    Código copiado!
                                  </p>
                                )}
                                {barCodeCopy === invoiceItem.FATURA_ID
                                  ? ''
                                  : ''}
                              </InvoiceButton>
                              <ReactTooltip
                                id="buttonTextDownload"
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <h4>Baixar via de pagamento</h4>
                                </div>
                              </ReactTooltip>
                              {!(barCodeCopy === invoiceItem.FATURA_ID) &&
                                !limited && (
                                  <InvoiceButton
                                    data-tip
                                    data-for="buttonTextDownload"
                                    style={{ justifyContent: 'center' }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      downloadPdf(invoiceItem)
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: 16,
                                        height: 16,
                                        alignSelf: 'center',
                                      }}
                                      alt="download"
                                      src={DownloadIcon}
                                    />
                                  </InvoiceButton>
                                )}
                            </div>
                          )}
                        </BoxInvoiceRight>
                        <div
                          style={{
                            display:
                              getSituation(invoiceItem) !== 2 &&
                              getSituation(invoiceItem) !== 6
                                ? 'flex'
                                : 'none',
                            width: '100%',
                            background: '#E6F2CE',
                            height: 50,
                            padding: 10,
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              gap: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/information.svg`}
                              alt="Infomação"
                              style={{ height: 24 }}
                            />
                            <span
                              style={{
                                color: '#494949',
                                fontSize: 11,
                                lineHeight: '14px',
                                fontWeight: 400,
                              }}
                            >
                              O pagamento desta fatura foi aprovado e será
                              validado em até 48h úteis.
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return null
                })}
              </BoxInvoicesPending>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/noinvoices.svg`}
                  alt="Todas as faturas foram pagas"
                />
                <span
                  style={{
                    fontFamily: 'Roboto',
                    color: '#2B2B2B',
                    fontSize: 36,
                    fontWeight: 500,
                    marginTop: 20,
                  }}
                >
                  Parabéns!!
                </span>
                <span
                  style={{
                    fontFamily: 'Roboto',
                    color: '#2B2B2B',
                    fontSize: 24,
                    fontWeight: 400,
                  }}
                >
                  Nenhuma fatura pendente!
                </span>
              </div>
            )
          ) : (
            // Shimmer Efects
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <BoxInvoiceRight
                style={{ marginTop: 20, marginRight: 10, borderRadius: 8 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Skeleton
                      style={{
                        width: 150,
                        height: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                        marginRight: 10,
                      }}
                    />
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                      }}
                    />
                  </div>
                </div>
                <span className="nameClient">
                  <Skeleton
                    style={{
                      width: 80,
                      height: 10,
                      marginBottom: 7,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 5,
                      }}
                    />
                  </span>
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 20,
                      }}
                    />
                  </span>
                </div>
                <SkeletonGreen
                  style={{
                    width: '100%',
                    height: 35,
                    borderRadius: 8,
                    marginBottom: 12,
                  }}
                />
              </BoxInvoiceRight>
              <BoxInvoiceRight
                style={{ marginTop: 20, marginRight: 10, borderRadius: 8 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Skeleton
                      style={{
                        width: 150,
                        height: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                        marginRight: 10,
                      }}
                    />
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                      }}
                    />
                  </div>
                </div>
                <span className="nameClient">
                  <Skeleton
                    style={{
                      width: 80,
                      height: 10,
                      marginBottom: 7,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 5,
                      }}
                    />
                  </span>
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 20,
                      }}
                    />
                  </span>
                </div>
                <SkeletonGreen
                  style={{
                    width: '100%',
                    height: 35,
                    borderRadius: 8,
                    marginBottom: 12,
                  }}
                />
              </BoxInvoiceRight>
              <BoxInvoiceRight style={{ marginTop: 20, borderRadius: 8 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Skeleton
                      style={{
                        width: 150,
                        height: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                        marginRight: 10,
                      }}
                    />
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                      }}
                    />
                  </div>
                </div>
                <span className="nameClient">
                  <Skeleton
                    style={{
                      width: 80,
                      height: 10,
                      marginBottom: 7,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 5,
                      }}
                    />
                  </span>
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 20,
                      }}
                    />
                  </span>
                </div>
                <SkeletonGreen
                  style={{
                    width: '100%',
                    height: 35,
                    borderRadius: 8,
                    marginBottom: 12,
                  }}
                />
              </BoxInvoiceRight>
            </div>
          )}
          <BoxPrice
            style={{
              opacity: totalCart <= 0 ? 0 : 1,
              bottom: totalCart <= 0 ? '0px' : '50px',
              transition: '0.5s ease',
              padding: 10,
              cursor: totalCart <= 0 ? 'default' : '',
              display: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 10,
                flexDirection: 'row',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  width: 227,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 8,
                  background: '#EDF1F5',
                }}
              >
                {invoicesId?.length &&
                  `FATURA${invoicesId?.length > 1 ? 'S' : ''} SELECIONADA${
                    invoicesId?.length > 1 ? 'S' : ''
                  } - ${invoicesId?.length}`}
              </span>
              <div
                style={{
                  justifyContent: 'center',
                  borderRadius: 8,
                  display: 'flex',
                  width: 227,
                  height: 40,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                  background: '#EDF1F5',
                }}
              >
                {`TOTAL - R$ ${totalCart.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`}
                <img
                  style={{
                    opacity: originalValue !== totalCart ? 1 : 0,
                    display: originalValue !== totalCart ? 'flex' : 'none',
                    transition: 'opacity 0.3s',
                    height: 20,
                  }}
                  data-tip
                  data-for="infoFees"
                  src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/information.svg`}
                  alt="Juros e multa"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                handlePay()
              }}
              disabled={totalCart <= 0 || loading}
            >
              {loading ? (
                <img
                  src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading.svg`}
                  alt="Carregando"
                  style={{ height: 40, margin: 0 }}
                />
              ) : (
                <>
                  <span>INICIAR PAGAMENTO</span>
                  <FontAwesomeIcon icon={faArrowRight} color="#fff" />
                </>
              )}
            </button>
          </BoxPrice>
        </Left>

        <div style={{ width: '100%' }} id="mobile">
          <Right>
            <Title>
              {invoices?.filter(
                (inv) => getSituation(inv) !== 2 || getSituation(inv) !== 6
              ).length &&
              invoices?.filter(
                (inv) => getSituation(inv) === 2 || getSituation(inv) === 6
              ).length > 0 ? (
                <button
                  type="button"
                  className="allInvoiceMobile"
                  onClick={() => checkAllInvoices(!markAll)}
                  style={{
                    border: 'none',
                    background: 'transparent',
                  }}
                >
                  <input
                    hidden
                    type="checkbox"
                    id="checkBox"
                    checked={markAll}
                    onChange={(e) => checkAllInvoices(e.target.checked)}
                  />
                  <div
                    style={{
                      transition: '0.2s',
                      border: '2px solid var(--secondaryColor)',
                      height: 24,
                      width: 24,
                      borderRadius: '50%',
                      background: markAll ? 'var(--secondaryColor)' : '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="#fff"
                      style={{ height: 12 }}
                    />
                  </div>
                </button>
              ) : null}
              <span className="title">Todas as faturas</span>
              <SelectFilterMobile
                allInv={allOpenInvoices}
                myInv={myOpenInvoices}
                otherInv={otherOpenInvoices}
                handleFilter={handleFilter}
              />
              <div className="numberInvTotal">
                <p>
                  {invoicesId?.length && invoicesId.length > 0
                    ? `${invoicesId.length} FATURA${
                        invoicesId.length > 1 ? 'S' : ''
                      }`
                    : `${getLimitedInvoicesNumber() || 0} ${
                        invoices && invoices.length > 1 ? 'Faturas' : 'Fatura'
                      }`}
                </p>
              </div>
            </Title>
            <BoxAllInvoices>
              {!loadingData ? (
                yearsInvoices?.map((year) => {
                  return (
                    <div id="ScrollContainer">
                      <YearTitle key={year}>{year}</YearTitle>
                      <div
                        style={{
                          padding: 20,
                          paddingBottom: 20,
                          marginBottom: -30,
                        }}
                      >
                        {invoices?.map((invoiceItem) => {
                          if (
                            parseFloat(invoiceItem.DT_REF_EDT.substr(3)) ===
                              year &&
                            (!limited ||
                              (invoiceItem.SIT_FATURA_EDT !== 'PAGA' &&
                                invoiceItem.SIT_FATURA_EDT !== 'PAGR'))
                          ) {
                            return (
                              <InvoiceV2
                                className={
                                  invoiceItem.FATURA_ID === shake
                                    ? 'shake_item'
                                    : ''
                                }
                                key={invoiceItem.NOTA_FISCAL}
                                style={{
                                  transition: 'all 0.4s',
                                  // maxHeight:171
                                }}
                                onClick={() => {
                                  if (
                                    invoiceItem.C1_TOTAL_CORRIGIDO !==
                                    invoiceItem.TOTAL_FATURA
                                  ) {
                                    console.log(
                                      invoiceItem.C1_TOTAL_CORRIGIDO,
                                      invoiceItem.TOTAL_FATURA
                                    )
                                    handleShowDetails(invoiceItem.NOTA_FISCAL)
                                  }
                                }}
                                open={showDetails === invoiceItem.NOTA_FISCAL}
                              >
                                <div
                                  style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {getMonthInvoice(
                                      invoiceItem.DT_REF_EDT.substr(0, 2)
                                    )}

                                    <div
                                      style={{
                                        height: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: generateColorStatus(
                                          invoiceItem
                                        ),
                                        fontSize: 10,
                                        fontFamily: 'Roboto',
                                        letterSpacing: 0.5,
                                        textTransform: 'uppercase',
                                        // ['TRAN', 'ABER', 'REAV'].includes(situation.SIT_FATURA_EDT)
                                        color:
                                          invoiceItem.SIT_FATURA_EDT ===
                                            'ABER' &&
                                          !(
                                            !checkDueDate(
                                              invoiceItem.DT_VENCIMENTO_EDT
                                            ) &&
                                            (getSituation(invoiceItem) === 2 ||
                                              getSituation(invoiceItem) === 6)
                                          ) &&
                                          invoiceItem.NUM_LOTE_ARREC === null
                                            ? '#222222'
                                            : '#FFFFFF',
                                        fontWeight: 500,
                                        borderRadius: 10,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        marginLeft: 8,
                                      }}
                                    >
                                      {generatingTextStatus(invoiceItem)}
                                    </div>
                                  </div>
                                  {invoiceItem.C1_TOTAL_CORRIGIDO !==
                                    invoiceItem.TOTAL_FATURA && (
                                    <img
                                      className="right_expand_img"
                                      alt="expand"
                                      src={ExpandMoreIcon}
                                    />
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    marginTop: 10,
                                  }}
                                >
                                  <InvoiceInfoTitle>
                                    Vencimento
                                  </InvoiceInfoTitle>
                                  <InvoiceInfoTitle>Valor</InvoiceInfoTitle>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <InvoiceInfoText>
                                    {invoiceItem.DT_VENCIMENTO_EDT}
                                  </InvoiceInfoText>
                                  <InvoiceInfoText>
                                    {`R$ ${parseFloat(
                                      invoiceItem.C1_TOTAL_CORRIGIDO
                                    ).toLocaleString('pt-BR', {
                                      minimumFractionDigits: 2,
                                    })}`}
                                  </InvoiceInfoText>
                                </div>
                                {!limited && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      flexDirection: 'row',
                                      marginTop: 10,
                                    }}
                                  >
                                    <InvoiceInfoTitle>Cliente</InvoiceInfoTitle>
                                  </div>
                                )}
                                {!limited && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <InvoiceInfoText>
                                      {limited
                                        ? 'Cliente atual'
                                        : invoiceItem.NOME_CLIENTE.length > 15
                                        ? `${invoiceItem.NOME_CLIENTE.substr(
                                            0,
                                            13
                                          )}...`.toLowerCase()
                                        : invoiceItem.NOME_CLIENTE.toLowerCase()}
                                    </InvoiceInfoText>
                                  </div>
                                )}
                                <div
                                  style={{
                                    marginTop: 14,
                                    height:
                                      showDetails === invoiceItem.NOTA_FISCAL
                                        ? 105
                                        : 0,
                                    overflow: 'hidden',
                                    transition: 'all 0.4s',
                                  }}
                                >
                                  <InvoiceInfoTitle>
                                    Detalhes da cobrança
                                  </InvoiceInfoTitle>

                                  <InvoiceRightExpanded
                                    style={{ marginTop: 4 }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 5,
                                      }}
                                    >
                                      <InvoiceRightExpandedText>
                                        Juros
                                      </InvoiceRightExpandedText>
                                      <InvoiceRightExpandedText>
                                        {parseFloat(
                                          invoiceItem.C1_JUROS
                                        ).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </InvoiceRightExpandedText>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 5,
                                      }}
                                    >
                                      <InvoiceRightExpandedText>
                                        Multa
                                      </InvoiceRightExpandedText>
                                      <InvoiceRightExpandedText>
                                        {parseFloat(
                                          invoiceItem.C1_MULTA
                                        ).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </InvoiceRightExpandedText>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 5,
                                      }}
                                    >
                                      <InvoiceRightExpandedText>
                                        Correção
                                      </InvoiceRightExpandedText>
                                      <InvoiceRightExpandedText>
                                        {parseFloat(
                                          invoiceItem.C1_CORRECAO
                                        ).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </InvoiceRightExpandedText>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: 5,
                                      }}
                                    >
                                      <InvoiceRightExpandedText
                                        style={{ fontWeight: 700 }}
                                      >
                                        Valor original
                                      </InvoiceRightExpandedText>
                                      <InvoiceRightExpandedText
                                        style={{ fontWeight: 700 }}
                                      >
                                        {parseFloat(
                                          invoiceItem.TOTAL_FATURA
                                        ).toLocaleString('pt-BR', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </InvoiceRightExpandedText>
                                    </div>
                                  </InvoiceRightExpanded>
                                </div>
                                {getAnalise(invoiceItem) ? (
                                  <div>
                                    <ReactTooltip
                                      id="buttonAnaliseInvoice"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 5,
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <h4>
                                          Fatura em análise, seu pagamento não
                                          pode ser realizado
                                        </h4>
                                      </div>
                                    </ReactTooltip>
                                    <button
                                      data-tip
                                      data-for="buttonAnaliseInvoice"
                                      type="button"
                                      className="invoice_fatura_analise"
                                    >
                                      <img
                                        src={InfoGrayIcon}
                                        className="InfoGrayIcon"
                                        alt="info"
                                      />
                                      <img
                                        src={InfoBlackIcon}
                                        className="InfoBlackIcon"
                                        alt="info"
                                      />
                                      Fatura em análise
                                    </button>
                                  </div>
                                ) : checkIfPaid(invoiceItem) ? (
                                  <div className="invoiceItemFooter">
                                    <ReactTooltip
                                      id="buttonTextDownload"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 5,
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <h4>Baixar via de pagamento</h4>
                                      </div>
                                    </ReactTooltip>
                                    {!limited && (
                                      <InvoiceButton
                                        data-tip
                                        data-for="buttonTextDownload"
                                        style={{
                                          justifyContent: 'center',
                                          width: '100%',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          downloadPdf(invoiceItem)
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: 16,
                                            height: 16,
                                            alignSelf: 'center',
                                          }}
                                          alt="download"
                                          src={DownloadIcon}
                                        />
                                        <div className="baixarComprovante">
                                          Baixar comprovante
                                        </div>
                                      </InvoiceButton>
                                    )}
                                  </div>
                                ) : getProc(invoiceItem) ? (
                                  <div id="pagamento_aprovado_container">
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img alt="info" src={InforCircleIcon} />
                                      <span id="pagamento_aprovado_texto">
                                        O pagamento desta fatura foi aprovado e
                                        será validado em até 48h úteis.
                                      </span>
                                    </div>
                                    <ReactTooltip
                                      id="buttonTextDownload"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 5,
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <h4>Baixar via de pagamento</h4>
                                      </div>
                                    </ReactTooltip>

                                    {!(barCodeCopy === invoiceItem.FATURA_ID) &&
                                      !limited && (
                                        <InvoiceButton
                                          data-tip
                                          data-for="buttonTextDownload"
                                          style={{ justifyContent: 'center' }}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            downloadPdf(invoiceItem)
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: 16,
                                              height: 16,
                                              alignSelf: 'center',
                                            }}
                                            alt="download"
                                            src={DownloadIcon}
                                          />
                                        </InvoiceButton>
                                      )}
                                  </div>
                                ) : (
                                  <div
                                    className="invoiceItemFooter"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',

                                      flexDirection: 'row',
                                      // marginTop: 12,

                                      paddingTop: 12,
                                    }}
                                  >
                                    {checkIfMandatory(invoiceItem) ? (
                                      <InvoiceButton
                                        style={{
                                          minWidth: 150,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          // justifyContent: 'center',
                                        }}
                                        active={checkIdList(
                                          invoiceItem.FATURA_ID
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          handlePaymentButton(invoiceItem)
                                        }}
                                      >
                                        <span>
                                          <img
                                            className="invoice_check_img"
                                            style={{
                                              width: 12,
                                              height: 12,
                                              alignSelf: 'center',
                                            }}
                                            alt="check"
                                            src={CheckIcon}
                                          />
                                        </span>
                                        <p className="invoice_selecionada">
                                          Obrigatório
                                        </p>
                                        <p className="invoice_nao_selecionada">
                                          Selecionar Fatura
                                        </p>
                                      </InvoiceButton>
                                    ) : (
                                      <InvoiceButton
                                        style={{ minWidth: 150 }}
                                        active={checkIdList(
                                          invoiceItem.FATURA_ID
                                        )}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          handlePaymentButton(invoiceItem)
                                        }}
                                      >
                                        <span>
                                          <img
                                            className="invoice_check_img_mobile"
                                            style={{
                                              width: 12,
                                              height: 12,
                                              alignSelf: 'center',
                                            }}
                                            alt="check"
                                            src={CheckIcon}
                                          />
                                        </span>
                                        <p className="invoice_selecionada_mobile">
                                          Fatura selecionada
                                        </p>
                                        <p className="invoice_nao_selecionada_mobile">
                                          Selecionar Fatura
                                        </p>
                                      </InvoiceButton>
                                    )}
                                    <ReactTooltip
                                      id="buttonTextCopy"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 5,
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                            color: '#FFFFFF',
                                          }}
                                        >
                                          Copiar código de barras
                                        </p>
                                      </div>
                                    </ReactTooltip>
                                    <InvoiceButton
                                      data-tip
                                      data-for="buttonTextCopy"
                                      style={{
                                        justifyContent: 'center',
                                        width: 36,
                                        alignItems: 'center',
                                        display: 'flex',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        copyBarCode(invoiceItem)
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: 16,
                                          height: 16,
                                          alignSelf: 'center',
                                        }}
                                        alt="copy"
                                        src={CopyIcon}
                                      />
                                    </InvoiceButton>
                                    <ReactTooltip
                                      id="buttonTextDownload"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 5,
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <h4>Baixar via de pagamento</h4>
                                      </div>
                                    </ReactTooltip>
                                    {!limited && (
                                      <InvoiceButton
                                        data-tip
                                        data-for="buttonTextDownload"
                                        style={{ justifyContent: 'center' }}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          downloadPdf(invoiceItem)
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: 16,
                                            height: 16,
                                            alignSelf: 'center',
                                          }}
                                          alt="download"
                                          src={DownloadIcon}
                                        />
                                      </InvoiceButton>
                                    )}
                                  </div>
                                )}
                              </InvoiceV2>
                            )
                          }
                          return <div key={invoiceItem.NOTA_FISCAL} />
                        })}
                      </div>
                    </div>
                  )
                })
              ) : (
                // Shimmer Efects
                <>
                  <div
                    style={{
                      width: '100%',
                      height: 50,
                      background: 'var(--secondaryColor)',
                    }}
                  />
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                </>
              )}
              <div id="mobile">
                <Footer />
              </div>
            </BoxAllInvoices>

            <BoxPriceMobile
              onClick={() => {
                handlePay()
              }}
              disabled={totalCart <= 0}
              style={{
                zIndex: totalCart <= 0 ? 10 : 20,
                opacity: totalCart <= 0 ? 0 : 1,
                cursor: totalCart <= 0 ? 'default' : '',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <span>
                  {invoicesId?.length
                    ? `${invoicesId?.length} FATURA${
                        invoicesId?.length > 1 ? 'S' : ''
                      }`
                    : ''}
                </span>
                <span style={{ fontSize: 19, fontWeight: 600 }}>
                  {`R$ ${totalCart.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                  })}`}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  overflow: 'hidden',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <img
                  style={{
                    overflow: 'hidden',
                    opacity: originalValue !== totalCart ? 1 : 0,
                    transition: 'opacity 0.3s',
                  }}
                  data-tip
                  data-for="infoFees"
                  src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/information.svg`}
                  alt="Juros e multa"
                />
                <span
                  style={{ fontSize: 19, fontWeight: 600, marginRight: 10 }}
                >
                  PAGAR
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 34,
                    width: 34,
                    borderRadius: 8,
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  {loading ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading-primary.svg`}
                      alt="Carregando"
                      style={{ height: 30, margin: 0 }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      color="var(--primaryColor)"
                    />
                  )}
                </div>
              </div>
            </BoxPriceMobile>
          </Right>
        </div>

        <div style={{ marginLeft: 14 }} id="web">
          <Right>
            <Title>
              {invoices?.filter(
                (inv) => getSituation(inv) !== 2 || getSituation(inv) !== 6
              ).length &&
              invoices?.filter(
                (inv) => getSituation(inv) === 2 || getSituation(inv) === 6
              ).length > 0 ? (
                <button
                  type="button"
                  className="allInvoiceMobile"
                  onClick={() => checkAllInvoices(!markAll)}
                  style={{
                    border: 'none',
                    background: 'transparent',
                  }}
                >
                  <input
                    hidden
                    type="checkbox"
                    id="checkBox"
                    checked={markAll}
                    onChange={(e) => checkAllInvoices(e.target.checked)}
                  />
                  <div
                    style={{
                      transition: '0.2s',
                      border: '2px solid var(--secondaryColor)',
                      height: 24,
                      width: 24,
                      borderRadius: '50%',
                      background: markAll ? 'var(--secondaryColor)' : '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="#fff"
                      style={{ height: 12 }}
                    />
                  </div>
                </button>
              ) : null}
              <span className="title">
                {totalCart > 0 ? 'Pagar Faturas' : 'Todas as faturas'}
              </span>
              <SelectFilterMobile
                allInv={allOpenInvoices}
                myInv={myOpenInvoices}
                otherInv={otherOpenInvoices}
                handleFilter={handleFilter}
              />
              <div className="numberInvTotal">
                <p>
                  {invoicesId?.length && invoicesId.length > 0
                    ? `${invoicesId.length} FATURA${
                        invoicesId.length > 1 ? 'S' : ''
                      }`
                    : `${getLimitedInvoicesNumber() || 0} ${
                        invoices && invoices.length > 1 ? 'Faturas' : 'Fatura'
                      }`}
                </p>
              </div>
            </Title>
            <BoxAllInvoices
              buttonShown={!loadingData && totalCart > 0}
              empty={
                !loadingData && totalCart === 0 && yearsInvoices.length === 0
              }
            >
              {!loadingData ? (
                totalCart > 0 ? (
                  <div id="ScrollBContainer" style={{}}>
                    <div className="SelectedInvoicesHeader">
                      <InvoiceInfoTitle>Fatura</InvoiceInfoTitle>
                      <InvoiceInfoTitle>Valor</InvoiceInfoTitle>
                    </div>
                    <div style={{ paddingTop: 18 }}>
                      {invoices?.map((invoiceItem) => {
                        if (checkIdList(invoiceItem.FATURA_ID)) {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 12,
                                fontFamily: 'Roboto',
                                fontWeight: 400,
                                fontSize: 14,
                                color: '#2B2B2B',
                                textTransform: 'uppercase',
                              }}
                            >
                              <p>
                                {`${
                                  getMonthInvoice(
                                    invoiceItem.DT_REF_EDT.substr(0, 2)
                                  ) +
                                  invoiceItem.DT_REF_EDT.substr(2, 6) +
                                  (invoiceItem.FD !== '0'
                                    ? `-${invoiceItem.FD}`
                                    : '')
                                }`}
                              </p>

                              <div
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <p style={{ fontWeight: 700 }}>
                                  {`R$ ${parseFloat(
                                    invoiceItem.C1_TOTAL_CORRIGIDO
                                  ).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                  })}`}
                                </p>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault()

                                    handlePaymentButton(invoiceItem)
                                  }}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 20,
                                    height: 20,
                                    marginLeft: 8,
                                    cursor: 'pointer',
                                    background: 'transparent',
                                    border: 0,
                                  }}
                                >
                                  <img
                                    alt="remove"
                                    style={{}}
                                    src={RemoveCircle}
                                  />
                                </button>
                              </div>
                            </div>
                          )
                        }

                        return null
                      })}
                    </div>
                  </div>
                ) : (
                  yearsInvoices?.map((year) => {
                    return (
                      <div id="ScrollContainer">
                        <YearTitle key={year}>{year}</YearTitle>
                        <div
                          style={{
                            padding: 20,
                            paddingBottom: 20,
                            marginBottom: -30,
                          }}
                        >
                          {invoices?.map((invoiceItem) => {
                            if (
                              parseFloat(invoiceItem.DT_REF_EDT.substr(3)) ===
                                year &&
                              (!limited ||
                                (invoiceItem.SIT_FATURA_EDT !== 'PAGA' &&
                                  invoiceItem.SIT_FATURA_EDT !== 'PAGR'))
                            ) {
                              return (
                                <InvoiceV2
                                  className={
                                    invoiceItem.FATURA_ID === shake
                                      ? 'shake_item'
                                      : ''
                                  }
                                  key={invoiceItem.NOTA_FISCAL}
                                  style={{
                                    transition: 'all 0.4s',
                                    // maxHeight:171
                                  }}
                                  onClick={() => {
                                    if (
                                      invoiceItem.C1_TOTAL_CORRIGIDO !==
                                      invoiceItem.TOTAL_FATURA
                                    ) {
                                      console.log(
                                        invoiceItem.C1_TOTAL_CORRIGIDO,
                                        invoiceItem.TOTAL_FATURA
                                      )
                                      handleShowDetails(invoiceItem.NOTA_FISCAL)
                                    }
                                  }}
                                  open={showDetails === invoiceItem.NOTA_FISCAL}
                                >
                                  <div
                                    style={{
                                      flexDirection: 'row',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {getMonthInvoice(
                                        invoiceItem.DT_REF_EDT.substr(0, 2)
                                      )}

                                      <div
                                        style={{
                                          height: 20,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          background: generateColorStatus(
                                            invoiceItem
                                          ),
                                          fontSize: 10,
                                          fontFamily: 'Roboto',
                                          letterSpacing: 0.5,
                                          textTransform: 'uppercase',
                                          color:
                                            invoiceItem.SIT_FATURA_EDT ===
                                              'ABER' &&
                                            !(
                                              !checkDueDate(
                                                invoiceItem.DT_VENCIMENTO_EDT
                                              ) &&
                                              (getSituation(invoiceItem) ===
                                                2 ||
                                                getSituation(invoiceItem) === 6)
                                            ) &&
                                            invoiceItem.NUM_LOTE_ARREC === null
                                              ? '#222222'
                                              : '#FFFFFF',
                                          fontWeight: 500,
                                          borderRadius: 10,
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          marginLeft: 8,
                                        }}
                                      >
                                        {generatingTextStatus(invoiceItem)}
                                      </div>
                                    </div>
                                    {invoiceItem.C1_TOTAL_CORRIGIDO !==
                                      invoiceItem.TOTAL_FATURA && (
                                      <img
                                        className="right_expand_img"
                                        alt="expand"
                                        src={ExpandMoreIcon}
                                      />
                                    )}
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      flexDirection: 'row',
                                      marginTop: 10,
                                    }}
                                  >
                                    <InvoiceInfoTitle>
                                      Vencimento
                                    </InvoiceInfoTitle>
                                    <InvoiceInfoTitle>Valor</InvoiceInfoTitle>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <InvoiceInfoText>
                                      {invoiceItem.DT_VENCIMENTO_EDT}
                                    </InvoiceInfoText>
                                    <InvoiceInfoText>
                                      {`R$ ${parseFloat(
                                        invoiceItem.C1_TOTAL_CORRIGIDO
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}`}
                                    </InvoiceInfoText>
                                  </div>

                                  {!limited && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        marginTop: 10,
                                      }}
                                    >
                                      <InvoiceInfoTitle>
                                        Cliente
                                      </InvoiceInfoTitle>
                                    </div>
                                  )}

                                  {!limited && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <InvoiceInfoText>
                                        {limited
                                          ? 'Cliente atual'
                                          : invoiceItem.NOME_CLIENTE.length > 15
                                          ? `${invoiceItem.NOME_CLIENTE.substr(
                                              0,
                                              13
                                            )}...`.toLowerCase()
                                          : invoiceItem.NOME_CLIENTE.toLowerCase()}
                                      </InvoiceInfoText>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      marginTop: 12,
                                      height:
                                        showDetails === invoiceItem.NOTA_FISCAL
                                          ? 100
                                          : 0,
                                      overflow: 'hidden',
                                      transition: 'all 0.4s',
                                      marginBottom:
                                        showDetails === invoiceItem.NOTA_FISCAL
                                          ? 12
                                          : 0,
                                    }}
                                  >
                                    <InvoiceInfoTitle>
                                      Detalhes da cobrança
                                    </InvoiceInfoTitle>

                                    <InvoiceRightExpanded
                                      style={{ marginTop: 4 }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          marginTop: 5,
                                        }}
                                      >
                                        <InvoiceRightExpandedText>
                                          Juros
                                        </InvoiceRightExpandedText>
                                        <InvoiceRightExpandedText>
                                          {parseFloat(
                                            invoiceItem.C1_JUROS
                                          ).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </InvoiceRightExpandedText>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          marginTop: 5,
                                        }}
                                      >
                                        <InvoiceRightExpandedText>
                                          Multa
                                        </InvoiceRightExpandedText>
                                        <InvoiceRightExpandedText>
                                          {parseFloat(
                                            invoiceItem.C1_MULTA
                                          ).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </InvoiceRightExpandedText>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          marginTop: 5,
                                        }}
                                      >
                                        <InvoiceRightExpandedText>
                                          Correção
                                        </InvoiceRightExpandedText>
                                        <InvoiceRightExpandedText>
                                          {parseFloat(
                                            invoiceItem.C1_CORRECAO
                                          ).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </InvoiceRightExpandedText>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          marginTop: 5,
                                        }}
                                      >
                                        <InvoiceRightExpandedText
                                          style={{ fontWeight: 700 }}
                                        >
                                          Valor original
                                        </InvoiceRightExpandedText>
                                        <InvoiceRightExpandedText
                                          style={{ fontWeight: 700 }}
                                        >
                                          {parseFloat(
                                            invoiceItem.TOTAL_FATURA
                                          ).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </InvoiceRightExpandedText>
                                      </div>
                                    </InvoiceRightExpanded>
                                  </div>
                                  {getAnalise(invoiceItem) ? (
                                    <div>
                                      <ReactTooltip
                                        id="buttonAnaliseInvoice"
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 5,
                                            alignItems: 'flex-start',
                                          }}
                                        >
                                          <h4>
                                            Fatura em análise, seu pagamento não
                                            pode ser realizado
                                          </h4>
                                        </div>
                                      </ReactTooltip>
                                      <button
                                        data-tip
                                        data-for="buttonAnaliseInvoice"
                                        type="button"
                                        className="invoice_fatura_analise"
                                      >
                                        <img
                                          src={InfoGrayIcon}
                                          className="InfoGrayIcon"
                                          alt="info"
                                        />
                                        <img
                                          src={InfoBlackIcon}
                                          className="InfoBlackIcon"
                                          alt="info"
                                        />
                                        Fatura em análise
                                      </button>
                                    </div>
                                  ) : checkIfPaid(invoiceItem) ? (
                                    <div className="invoiceItemFooter">
                                      <ReactTooltip
                                        id="buttonTextDownload"
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 5,
                                            alignItems: 'flex-start',
                                          }}
                                        >
                                          <h4>Baixar via de pagamento</h4>
                                        </div>
                                      </ReactTooltip>
                                      {!limited && (
                                        <InvoiceButton
                                          data-tip
                                          data-for="buttonTextDownload"
                                          style={{
                                            justifyContent: 'center',
                                            width: '100%',
                                            marginTop: 10,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            downloadPdf(invoiceItem)
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: 16,
                                              height: 16,
                                              alignSelf: 'center',
                                            }}
                                            alt="download"
                                            src={DownloadIcon}
                                          />
                                          <div className="baixarComprovante">
                                            Baixar comprovante
                                          </div>
                                        </InvoiceButton>
                                      )}
                                    </div>
                                  ) : getProc(invoiceItem) ? (
                                    <div id="pagamento_aprovado_container">
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img alt="info" src={InforCircleIcon} />
                                        <span id="pagamento_aprovado_texto">
                                          O pagamento desta fatura foi aprovado
                                          e será validado em até 48h úteis.
                                        </span>
                                      </div>
                                      <ReactTooltip
                                        id="buttonTextDownload"
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 5,
                                            alignItems: 'flex-start',
                                          }}
                                        >
                                          <h4>Baixar via de pagamento</h4>
                                        </div>
                                      </ReactTooltip>

                                      {!(
                                        barCodeCopy === invoiceItem.FATURA_ID
                                      ) &&
                                        !limited && (
                                          <InvoiceButton
                                            data-tip
                                            data-for="buttonTextDownload"
                                            style={{ justifyContent: 'center' }}
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              downloadPdf(invoiceItem)
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: 16,
                                                height: 16,
                                                alignSelf: 'center',
                                              }}
                                              alt="download"
                                              src={DownloadIcon}
                                            />
                                          </InvoiceButton>
                                        )}
                                    </div>
                                  ) : (
                                    <div
                                      className="invoiceItemFooter"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',

                                        flexDirection: 'row',
                                        // marginTop: 12,

                                        paddingTop: 12,
                                      }}
                                    >
                                      {checkIfMandatory(invoiceItem) ? (
                                        <InvoiceButton
                                          style={{
                                            minWidth: 150,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            // justifyContent: 'center',
                                          }}
                                          active={checkIdList(
                                            invoiceItem.FATURA_ID
                                          )}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            handlePaymentButton(invoiceItem)
                                          }}
                                        >
                                          <span>
                                            <img
                                              className="invoice_check_img"
                                              style={{
                                                width: 12,
                                                height: 12,
                                                alignSelf: 'center',
                                              }}
                                              alt="check"
                                              src={CheckIcon}
                                            />
                                          </span>
                                          <p className="invoice_selecionada">
                                            Obrigatório
                                          </p>
                                          <p className="invoice_nao_selecionada">
                                            Selecionar Fatura
                                          </p>
                                        </InvoiceButton>
                                      ) : (
                                        <InvoiceButton
                                          style={{ minWidth: 150 }}
                                          active={checkIdList(
                                            invoiceItem.FATURA_ID
                                          )}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            handlePaymentButton(invoiceItem)
                                          }}
                                        >
                                          <span>
                                            <img
                                              className="invoice_check_img"
                                              style={{
                                                width: 12,
                                                height: 12,
                                                alignSelf: 'center',
                                              }}
                                              alt="check"
                                              src={CheckIcon}
                                            />
                                          </span>
                                          <p className="invoice_selecionada">
                                            Fatura selecionada
                                          </p>
                                          <p className="invoice_nao_selecionada">
                                            Selecionar Fatura
                                          </p>
                                        </InvoiceButton>
                                      )}
                                      <ReactTooltip
                                        id="buttonTextCopy"
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 5,
                                            alignItems: 'flex-start',
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontFamily: 'Roboto',
                                              fontSize: 14,
                                              color: '#FFFFFF',
                                            }}
                                          >
                                            Copiar código de barras
                                          </p>
                                        </div>
                                      </ReactTooltip>
                                      <InvoiceButton
                                        data-tip
                                        data-for="buttonTextCopy"
                                        style={{
                                          justifyContent: 'center',
                                          width: 36,
                                          alignItems: 'center',
                                          display: 'flex',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          copyBarCode(invoiceItem)
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: 16,
                                            height: 16,
                                            alignSelf: 'center',
                                          }}
                                          alt="copy"
                                          src={CopyIcon}
                                        />
                                      </InvoiceButton>
                                      <ReactTooltip
                                        id="buttonTextDownload"
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 5,
                                            alignItems: 'flex-start',
                                          }}
                                        >
                                          <h4>Baixar via de pagamento</h4>
                                        </div>
                                      </ReactTooltip>

                                      {!limited && (
                                        <InvoiceButton
                                          data-tip
                                          data-for="buttonTextDownload"
                                          style={{ justifyContent: 'center' }}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            downloadPdf(invoiceItem)
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: 16,
                                              height: 16,
                                              alignSelf: 'center',
                                            }}
                                            alt="download"
                                            src={DownloadIcon}
                                          />
                                        </InvoiceButton>
                                      )}
                                    </div>
                                  )}
                                </InvoiceV2>
                              )
                            }
                            return <div key={invoiceItem.NOTA_FISCAL} />
                          })}
                        </div>
                      </div>
                    )
                  })
                )
              ) : (
                // Shimmer Efects
                <>
                  <div
                    style={{
                      width: '100%',
                      height: 50,
                      background: 'var(--secondaryColor)',
                    }}
                  />
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                  <Invoice>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '20px 20px 15px 0px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                marginRight: 20,
                              }}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 120,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                          <span>
                            <Skeleton
                              style={{
                                width: 80,
                                height: 10,
                                margin: 5,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <Skeleton
                        style={{
                          width: 80,
                          height: 25,
                          borderRadius: 16,
                        }}
                      />
                    </div>
                  </Invoice>
                </>
              )}

              <BoxInvoicePaymentContainer
                style={{
                  display: !loadingData && totalCart > 0 ? 'block' : 'none',
                }}
              >
                <div className="JMCcontainer">
                  <p>Juros + Multas + Correções</p>
                  <p className="JMCBold">
                    R$
                    {' '}
                    {(penalty + fees + correction).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                <BoxInvoicePaymentButton
                  onClick={() => {
                    handlePay()
                  }}
                  disabled={totalCart <= 0 || loading}
                  type="button"
                >
                  {loading ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading.svg`}
                      alt="Carregando"
                      style={{ height: 40, margin: 0 }}
                    />
                  ) : (
                    <p>
                      Iniciar pagamento (
                      {`R$ ${totalCart.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                      })}`}
                      )
                    </p>
                  )}
                </BoxInvoicePaymentButton>
              </BoxInvoicePaymentContainer>

              <div id="mobile">
                <Footer />
              </div>
            </BoxAllInvoices>

            <BoxPriceMobile
              onClick={() => {
                handlePay()
              }}
              disabled={totalCart <= 0}
              style={{
                zIndex: totalCart <= 0 ? 10 : 20,
                opacity: totalCart <= 0 ? 0 : 1,
                cursor: totalCart <= 0 ? 'default' : '',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <span>
                  {invoicesId?.length
                    ? `${invoicesId?.length} FATURA${
                        invoicesId?.length > 1 ? 'S' : ''
                      }`
                    : ''}
                </span>
                <span style={{ fontSize: 19, fontWeight: 600 }}>
                  {`R$ ${totalCart.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                  })}`}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  overflow: 'hidden',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <img
                  style={{
                    overflow: 'hidden',
                    opacity: originalValue !== totalCart ? 1 : 0,
                    transition: 'opacity 0.3s',
                  }}
                  data-tip
                  data-for="infoFees"
                  src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/information.svg`}
                  alt="Juros e multa"
                />
                <span
                  style={{ fontSize: 19, fontWeight: 600, marginRight: 10 }}
                >
                  PAGAR
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 34,
                    width: 34,
                    borderRadius: 8,
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  {loading ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading-primary.svg`}
                      alt="Carregando"
                      style={{ height: 30, margin: 0 }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      color="var(--primaryColor)"
                    />
                  )}
                </div>
              </div>
            </BoxPriceMobile>
          </Right>
        </div>
      </Box>
      <ReactTooltip
        id="infoFees"
        disable={originalValue === totalCart}
        place="top"
        type="dark"
        effect="solid"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            alignItems: 'flex-start',
          }}
        >
          <h3>
            Valor original:
            <span>
              {` R$ ${originalValue.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`}
            </span>
          </h3>
          <span>Devido ao atraso, serão cobrados:</span>
          <h4>
            {`Juros: R$ ${fees.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}
          </h4>
          <h4>
            {`Multas: R$ ${penalty.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}
          </h4>
          <h4>
            {`Correção: R$ ${correction.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}
          </h4>
          <h3>
            Valor Atualizado:
            <span>
              {` R$ ${totalCart.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`}
            </span>
          </h3>
        </div>
      </ReactTooltip>
      <div id="web">
        <Footer />
      </div>
    </Container>
  )
}

export default connect((state: StateInitial) => ({ modules: state }))(Home)
