import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const apiPaymentLink = axios.create({
  baseURL: process.env.REACT_APP_API_PAYMENT_LINK,

})

export const mundiPagg = axios.create({
  baseURL: process.env.REACT_APP_API_MUNDIPAG_HOST
});
