import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    //padding: 15px;
  }
`

export const Box = styled.div`
  width: 395px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`

export const Title = styled.h1`
  font-size: 24px;
  font-family: 'Roboto';
  margin-bottom: 30px;
  font-weight: 500;
  color: #2b2b2b;
  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 20px;
  }
`

export const Top = styled.div`
  width: 395px;
  padding: 30px;
  margin-bottom: 15px;
  color: #fff;
  background-color: var(--primaryColor);
  border-radius: 8px;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`

export const TableInvoices = styled.div`
  border: 3px solid #fff;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  border-radius: 16px;
  margin-top: 5px;
  div:first-child {
    border-top: none;
  }
  > div {
    border-top: 3px solid #fff;
    padding: 10px;
    width: 100%;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        font-size: 25px;
        font-weight: 500;
        font-family: 'Roboto';
      }
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 10px;
    span {
      font-size: 20px !important;
      font-weight: 500;
      font-family: 'Roboto';
    }
  }
`

export const Bottom = styled.div`
  width: 395px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 30px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 8px;
  span {
    color: #494949;
    font-size: 13px;
    font-family: 'Roboto';
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

interface ButtonProps {
  isActive: boolean
}

export const Button = styled.button`
  border: none;
  min-height: 48px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  border-radius: 8px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  &.proofButton {
    border: 2px solid var(--primaryColor);
    color: #fff;
    background-color: var(--primaryColor);
    margin-bottom: 20px;
  }

  &.homeButton {
    border: 2px solid var(--primaryColor);
    color: var(--primaryColor);
    background-color: #fff;
  }
`

export const ButtonComprov = styled.button<ButtonProps>`
  border: none;
  min-height: 48px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  width: 100%,
  border-radius: 8px;
  background-color: #fff;
  text-decoration: none;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  &.proofButton{
    border: 2px solid var(--primaryColor);
    color: #fff;
    background-color: var(--primaryColor);
    margin-bottom: 20px;
    visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')}
  }
  &.homeButton{
    border: 2px solid var(--primaryColor);
    color: var(--primaryColor);
    background-color: #fff;
  }
`
