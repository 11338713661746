import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Box = styled.div`
  width: 395px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  @media only screen and (max-width: 768px){
    width: 100%;
    padding: 15px;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-family: 'Roboto';
  margin-bottom: 30px;
  font-weight: 500;
  color: #2B2B2B;
  @media only screen and (max-width: 768px){
    width: 100%;
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 20px;
  }
`
export const Top = styled.div`
  width: 395px;
  padding: 30px;
  margin-bottom: 15px;
  color: #282828;
  background-color: #fff;
  border-radius: 8px;
  >div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (max-width: 768px){
    width: 100%;
    padding: 20px;
  }
`;

export const TableInvoices = styled.div`
  border: 2px solid var(--primaryColor);
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  border-radius: 16px;
  margin-top: 5px;
  div:first-child{
    border-top: none;
  }
  >div{
    border-top: 2px solid var(--primaryColor);
    padding: 10px;
    width: 100%;
    div{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span{
        font-size: 25px;
        font-weight: 500;
        font-family: 'Roboto';
      }
    }
  }
  @media only screen and (max-width: 768px){
    border-radius: 10px;
    span{
      font-size: 20px !important;
      font-weight: 500;
      font-family: 'Roboto';
    }
  }
`;

export const Bottom = styled.div`
  width: 395px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 30px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 8px;
  height: 84px;
  span{
    color: #494949;
    font-size: 13px;
    font-family: 'Roboto';
  }
  @media only screen and (max-width: 768px){
    width: 100%;
  }
`;

export const Button = styled.button`
  border: none;
  min-height: 48px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  border-radius: 8px;
  color: #fff;
  background-color: var(--primaryColor);
`;

export const TextFooterBox = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100%;
  h4{
    color: var(--secondaryColor);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roboto';
    font-weight: 400;
  }
  h1{
    color: var(--secondaryColor);
    font-size: 20px;
    line-height: 24px;
    font-family: 'Roboto';
    font-weight: 500;
  }
`;
