import styled from 'styled-components'

interface ModalProps {
  visible?: boolean
}

export const ModalContainer = styled.div<ModalProps>`
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 30;

  transition: all 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)}; ;
`

export const Container = styled.div<ModalProps>`
  width: 100vw;
  max-width: 600px;
  // height:  -webkit-fill-available;

  display: flex;
  flex-direction: column;

  background: #ffffff;
  border-radius: 36px;

  overflow: hidden;

  opacity: ${(props)=>(props.visible? 1: 0)};
  transition: all 0.3s;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  //border-top-left-radius: 36px;
  //border-top-right-radius: 36px;
  background: #092A60;
  padding-left: 28px;
  padding-right: 28px;
  p {
    margin: 0;
  }

  .modal_header_title {
    font: normal normal 500 24px/24px Roboto;
    color: #ffffff;
    text-align: center;
  }


`

export const Body = styled.div`
  background-color: #ffffff;
  border-bottom-right-radius: 36px;
  border-bottom-left-radius: 36px;
  display: flex;
  flex-direction: row;
  padding: 40px;

  p {
    margin: 0;
  }

  img {
    width: 150px;
    height: 150px;
  }

  .main_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 50px;
  }

  .modal_text {
    font: normal normal 300 18px/28px Roboto;
    color: #2b2b2b;
    text-align: center;
    text-align: left;
  }

  .modal_button {
    border: 0;
    height: 40px;
    border-radius: 20px;
    background: #7d9828;
    color: #ffffff;
    font: normal normal 500 16px/28px Roboto;
    margin-top: 40px;
  }
`

export const Modal = styled.div`
  width: 600px;
  border-radius: 36px;
  // padding: 40px;
  background-color: #fff;
  opacity: 1;
  display: flex;
  flex-direction: column;
  // gap: 10px;
  // grid-template-columns: auto auto;
  // align-items: center;
  img {
    width: 250px;
    margin: 0;
  }
  h2 {
    width: 100%;
    font-size: 36px;
    color: #2b2b2b;
  }
  > div {
    padding-left: 40px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  p {
    font-size: 16px;
    line-height: 1.6rem;
    color: #2b2b2b;
    font-weight: 300;
  }
  button {
    width: 214px;
    height: 40px;
    border: none;
    color: #fff;
    font-size: 20px;
    border-radius: 8px;
    background-color: var(--primaryColor);
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    border-radius: 8px;
    padding: 40px;
    background-color: #fff;
    opacity: 1;
    display: flex;
    gap: 0px;
    flex-direction: column;
    align-items: flex-end;
    img {
      width: 160px;
      position: fixed;
      right: 12%;
    }
    > div {
      margin-top: 80px;
      height: 250px;
      padding-left: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
    }
    h2 {
      font-size: 30px;
      color: #2b2b2b;
    }
    p {
      margin-top: 1.5rem;
      font-size: 20px;
      line-height: 1.6rem;
      color: #494949;
    }
    button {
      margin-top: 3rem;
      width: 100%;
      min-height: 40px;
      border: none;
      color: #fff;
      font-size: 20px;
      border-radius: 8px;
      background-color: var(--primaryColor);
    }
  }
`
