import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  z-index: 0;
  //top: 0px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px){
    height: 50px;
  }
  @media only screen and (min-width:769px){
    position: absolute;
    bottom: 0;

    @media only screen and (max-height:380px){
      display:none;
    }

  }
`;

export const Box = styled.div`
  max-width: 1250px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-family: 'Roboto';
  padding: 15px;
  padding-left: 0;
  padding-right: 0;

  .OrangeFooter{
    margin-left: 28px;
  }
  
  a {
    cursor: pointer;
    text-decoration: none;
    color: #494949;
    display: flex;
    align-items: center;
    color: #494949;
    font-size: 12px;
    img{
      height: 12px;
      margin-left: 6px;
    }
  }
  div{
    display: flex;
    align-items: center;
    color: #494949;
    font-size: 12px;
  }
  @media only screen and (max-width: 768px){
    max-width: 100vw;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
  justify-content: space-between;

  .OrangeFooter{
    margin-left: 0;
  }

    a{
      height: 20px;
      display: flex;
      justify-content:center;
      align-items: center;
      color: '#494949';
      font-size: 12px;
      img{
        height: 12px;
        margin-left: 6px;
      }
    }
    div{
      height: 20px;
      display: flex;
      justify-content:center;
      align-items: center;
      color: '#494949';
      font-size: 12px;
    }
  }
`;
