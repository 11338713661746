import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  top: 0px;
  height: 100vh;
  background-color: #607d8b;
  align-items: center;
  justify-content: center;
  .grecaptcha-badge {
    visibility: hidden;
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    //min-height: 100vh;
    //max-height: -webkit-fill-available;
    //overflow-y: scroll;
    flex-direction: column;

    .thisRecap {
      display: none;
    }
  }
`

export const Box = styled.div`
  background-color: #f2f3f3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 768px) {
    min-height: 100%;
    overflow-y: scroll;
    display: flex;
    //padding-bottom: 300px;
    flex-direction: column;
    align-items: flex-start;
    //justify-content: center;
    //position: fixed;
  }
`

export const BoxLeft = styled.div`
  width: 70vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background: transparent linear-gradient(50deg, #004f96, #003463) 0% 0%
    no-repeat padding-box;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: auto;
  padding-left: 200px;

  .box_left_button {
    height: 40px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    font: normal normal 500 14px/19px Roboto;
    background: transparent;
    color: #ffffff;
    align-self: flex-start;
    text-decoration: none;
  }

  .box_left_title {
    font-size: 50px;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    font-family: Roboto;
    margin-bottom: 30px;
  }

  .box_left_small {
    font-family: Roboto;

    font-size: 16px;
    line-height: 20px;
    font-weight: 300;

    color: #ffffff;
  }

  .box_left_bold {
    font-weight: bold;
  }

  .box_left_footer {
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    font: normal normal normal 12px/16px Roboto;
    color: #ffffff;
  }

  .align_self_start {
    align-self: flex-start;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 768px) {
    //padding: 20px 10px 10px 10px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 72px;
    padding-bottom: 80px;
    width: 100vw;
    //height: 50vh;
    justify-content: flex-start;
    //position: fixed;
    //overflow: hidden;
    display: flex;
    align-items: center;
    top: 0px;
    //min-height: 500px;
    overflow: visible;
    flex: 1;

    .box_left_title {
      font-size: 30px;
      color: #ffffff;
      text-align: left;
      font-weight: bold;
      font-family: Roboto;
      margin-bottom: 8px;
    }

    .box_left_small {
      font-family: Roboto;

      font-size: 14px;
      line-height: 18px;
      font-weight: 300;

      color: #ffffff;
    }
    .box_left_footer{
      display: none;
    }
  }
`

export const BoxTwoImages = styled.div`
  display: flex;
  /* margin-left: 40px; */
  flex-direction: row;
  /* background-color: red; */
  //margin-top: 40px;
  margin-bottom: 30px;
  max-height: 360px;
  #imgPhone {
    margin-top: -20px;
    margin-right: 100px;
    height: 340px;
  }
  #imgText {
    margin-top: 20px;
    margin-left: 30px;
    height: 50px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    max-height: 50px;
    align-self: flex-start;

    #imgPhone {
      display: none;
      margin-top: 0px;
      height: 0px;
    }
    #imgText {
      margin-top: 5px;
      position: relative;
      margin-left: 0px;
      height: 40px;
    }
  }
`

export const BoxTextAndCard = styled.div`
  display: flex;
  flex-direction: column;
  //background-color: red;
  align-items: center;
  justify-content: center;
  max-width: 373px;
  margin: 20px 0px 0px 0px;
  @media only screen and (max-width: 768px) {
    //margin-top: 100px;
    margin: 0px 0px 0px 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

export const BoxRight = styled.div`
  //padding: 40px;
  width: 30vw;
  height: 100vh;
  //padding: 150px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding-left: 0px;
  .box_right_footer_mobile{
    display: none;

  }

  a {
    text-decoration: none;
  }
  > div {
    #logoWeb {
      height: 100px;
      display: flex;
    }
    #logoMobile {
      //margin-bottom: 30px;
      height: 148px;
      display: none;
    }
  }

  .login_footer_link {
    position: fixed;
    bottom: 10px;

    @media only screen and (max-width: 768px) {
      position: relative;
      padding-top: 40px;
      padding-bottom: 20px;
      border-bottom: 1px solid #676767;

      //display: none;
    }
  }

  .login_img_container {
    text-align: center;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-bottom: 40px;

    p {
      margin-top: 20px;
    }
  }

  .login_form_container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 16px 16px 16px #22222229;
    border-radius: 36px;
    margin-left: -110px;
    max-width: 370px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 36px;
    padding-bottom: 36px;

    .ml-10 {
      margin-left: 10px;
    }

    .mt-60 {
      margin-top: 60px;
    }

    .pb-30 {
      padding-bottom: 30px;
    }

    .pt-30 {
      padding-top: 30px;
    }

    .bb-gray {
      border-bottom: 1px #f0f0f0 solid;
    }

    .login_form_title {
      font: normal normal bold 18px/28px Roboto;
      color: #18aa36;
      margin: 0;
      margin-bottom: 5px;
    }
    .login_form_subtitle {
      font: normal normal normal 14px/18px Roboto;
      color: #676767;
      margin: 0;
      margin-bottom: 20px;
      max-width: 80%;
    }

    .login_input_label {
      margin: 0;
      margin-bottom: 5px;
      font: normal normal 500 11px/15px Roboto;
      color: #676767;
    }

    .login_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      //width: 100%;
    }

    .login_input_container {
      height: 40px;
      background-color: #ffffff;
      border-radius: 20px;
      border: 1px solid #9f9f9f;
      font: normal normal 300 16px/19px Roboto;
    }
    .login_input_password {
      height: 40px;
      background-color: transparent;
      //border-radius: 20px;
      font: normal normal 300 16px/19px Roboto;
      //border: transparent;
      border: 0;
    }

    .input_password_container {
      // width: 280px;

      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #9f9f9f;
      font: normal normal 300 14px/19px Roboto;
      input {
        /*border-radius: 16px;
        //height: 48px;
        flex: 1;
        border: 0;
        padding-left: 16px;*/
      }

      .input_icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 16px;
        cursor: pointer;
        height: 100%;
      }

      .input_password_icon {
        width: 17px;
        height: 17px;
      }
    }
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }

  .login_form_bottom_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
      color: #18aa36;
    }
  }

  .login_form_bottom_text {
    text-align: center;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #676767;
  }

  @media only screen and (max-width: 768px) {
    > div {
      #logoWeb {
        height: 148px;
        display: none;
      }
      #logoMobile {
        height: 40px;
        width: 50vw;
        display: flex;
      }
    }
    display: flex;
    //padding: 10px;
    //width: 100%;
    //max-height: 65%;
    //min-height: 100vh;
    justify-content: center;
    align-items: center;
    //position: fixed;
    bottom: 0px;
    flex-direction: column;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;

    //overflow-y: scroll;
    margin-left: 0px;
    width: 100vw;
    max-width: 100vw;
    //margin-top: -50px;
    .login_form_container {
      margin-left: 0;
      max-width: 100%;
      width: 100%;
      margin: 0;
      padding-top: 36px;
      padding-left: 52px;
      padding-right: 52px;
      margin-top: -72px;

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    box-shadow: none;

    }

    .box_right_footer_mobile{
      display: flex;
      //padding-top: 20px;
      margin-bottom: 20px;

    }
  }
`

interface FormButtonProps {
  isFocused?: boolean
}

export const FormButton = styled.button<FormButtonProps>`
  background: ${(props) => (props.isFocused ? '#18aa36' : '#FFFFFF')};
  border: #18aa36 1px solid;
  border-radius: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 500 14px/19px Roboto;
  color: ${(props) => (props.isFocused ? '#FFFFFF' : '#18AA36')};
  width: 100px;
  transition: all 0.2s;
`

export const Title = styled.div`
  font-size: 26px;
  color: #536979;
  line-height: 34px;
  letter-spacing: -0.52px;
  font-family: 'Nunito';
  font-weight: 800;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    text-align: center;
    color: #536979;
    line-height: 24px;
    letter-spacing: -0.52px;
    font-family: 'Nunito';
    font-weight: 800;
  }
`

export const Description = styled.div`
  font-size: 18px;
  color: #536979;
  line-height: 26px;
  letter-spacing: -0.36px;
  font-family: 'Nunito';
  font-weight: 400;
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
  }
`

export const Images = styled.div`
  width: 150px;
  /* background-color: red; */
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
`

export const TextFoot = styled.div`
  font-size: 12px;
  color: #536979;
  letter-spacing: -0.24px;
  font-family: 'Nunito';
  font-weight: 400;
  margin-left: 20px;
  font: normal normal normal 11px/16px Nunito;
  letter-spacing: 0px;
  color: #536979;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-left: 0px;
    line-height: 14px;
    width: 200px;
  }
`

export const Label = styled.label`
  text-align: left;
  font: normal normal 500 12px/16px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media only screen and (max-width: 768px) {
    /*font-size: 18px;
    text-align: center;
    margin-top: 0px;
    line-height: 22px;*/
  }
`
export const Input = styled.input`
  // width: 280px;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 16px;
  border: none;
  text-align: left;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const Button = styled.button`
  width: 280px;
  height: 48px;
  border-radius: 16px;
  border: none;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto';
  background-color: var(--primaryColor);
`
