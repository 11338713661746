import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const BoxInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-style: solid;
  border-width: 0.5px;input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  border-color: #707070;
  padding-left: 10px;
  padding-right: 10px;
  input{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-family: 'Roboto';
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: 'Roboto';
  color: #494949;
`;

export const Span = styled.span`
  font-size: 14px;
  margin-left: 10px;
  color:red;
  height: 10px;
`;

// export const InputText = styled.input`
//   width: 100%;
//   height: 48px;
//   border-radius: 8px;
//   border: none;
//   text-align: center;
//   border: 0.5px solid #fff;
//   -moz-appearance: textfield;
//   &::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
//   &.error{
//     animation: shake 0.2s ease-in-out 0s 2;
//     border: 0.5px solid red;
//   }
//   @keyframes shake {
//     0% { margin-left: 0rem; }
//     20% { margin-left: 0.2rem; }
//     50% { margin-left: -0.2rem; }
//     80% { margin-left: 0.2rem; }
//     100% { margin-left: 0rem; }
//   }
//   @media only screen and (max-width: 768px){
//     margin-top: 10px;
//     margin-bottom: 10px;
//   }
// `;
