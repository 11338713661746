import styled from 'styled-components'

export const BodyContainer = styled.div`
  display: flex;
  top: 0px;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    0deg,
    var(--backgroundColorLight) 93%,
    var(--secondaryColor) 64px
  );
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 768px) {
    //max-height: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  #mobile {
    display: none;
  }
  #web {
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    overflow: scroll;
    #mobile {
      display: flex;
    }
    #web {
      display: none;
    }
  }
`

export const Box = styled.div`
  width: 818px;
  @media only screen and (max-width: 768px) {
    width: 100vw;
    padding: 10px;
    overflow: scroll;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Title = styled.span`
  color: #2b2b2b;
  font-size: 24px;
  font-family: 'Roboto';
  line-height: 32px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`

export const BtnBack = styled.button`
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: #064076;
  background-color: #fff;
  transition: 300ms;
  border: 1px solid #fff;
  font-family: 'Roboto';
  font-weight: 500;
  &:hover {
    color: #064076;
    border: 1px solid #064076;
  }
  &:active {
    background-color: #eee;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  width: 394px;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: flex-start; */
  height: 437px;
  background: #fff;
  padding-bottom: 8px;
  border-radius: 8px;
  /* margin-bottom: 200px; */
  > div {
    padding: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--secondaryColor);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  > div {
    height: 350px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    > div:first-child {
      padding: 15px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: var(--secondaryColor);
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      margin: 0;
    }
    > span {
      padding: 0 15px;
    }
    margin-bottom: 10px;
    width: 394px;
  }
  @media only screen and (max-width: 768px) {
    > div {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`

export const ScrollInvoice = styled.div`
  height: 150px;
  padding: 0 15px;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const InvoiceDetails = styled.div`
  border: 1px solid var(--primaryColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* width: 100vw; */
  }
`

export const TitleInvoiceDetails = styled.span`
  font-size: 16px;
  color: #494949;
  font-family: 'Roboto';
  font-weight: 500;
`

export const DescriptionInvoice = styled.span`
  font-size: 13px;
  font-family: 'Roboto';
  color: #858585;
`

export const ButtonPay = styled.button`
  border: none;
  height: 50px;
  background: var(--primaryColor);
  color: #fff;
  font-family: 'Roboto';
  border-radius: 8px;
  font-weight: 500;
  margin: 0 15px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
