import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PDFDownloadLink } from "@react-pdf/renderer";
import ClipLoader from "react-spinners/ClipLoader";
import { ProofPayment } from '../../../../components/PDF';
import { PaymentDTO } from '../../../../utils/models';
import { Container, Box, Title,Top , Bottom, Button, TableInvoices, ButtonComprov } from './styles';

interface Invoice{
  data: PaymentDTO;
}

const Success: React.FC = () => {
  const {state} = useLocation<Invoice>();
  const history = useHistory();
  const [printProofPayment, setPrintProofPayment] = useState<PaymentDTO>();
  const [hasPrint, setHasPrint] = useState(false);
  const [total, setTotal] = useState(0);
  const [color, setColor] = useState("#2CE0BC");
  const [limited,setLimited] = useState(true)

  useEffect(() => {
    // setLimited(modules.LIMITED)

    const infoPermit = sessionStorage.getItem('infoPermission') === 'true'

    setLimited(!infoPermit)
  }, [])

  function printProof():void{
    setHasPrint(true);
    setTimeout(() => {
      // setHasPrint(false);
    }, 2000);
  }

  useEffect(() => {
    const tempPayment = localStorage.getItem('statusPaymentResume');
    if(tempPayment){
      setPrintProofPayment(JSON.parse(tempPayment))
      setTotal(JSON.parse(tempPayment).total)
    }else{
      history.push('/');
    }
  }, [state]);

  return (
    <Container>
      <Box>
        <Title>Pagamento Recebido</Title>
        <Top>
          <div>
            <span>
              Fatura
            </span>
            <span>
              Valor
            </span>
          </div>
          <TableInvoices>
            {printProofPayment?.faturas?.map(invoice => {
              return(
                <div key={invoice.id}>
                  <div>
                    <span>{invoice.ref}</span>
                    <span>{`R$ ${invoice.total.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                  </div>
                </div>
              )
            })}
          </TableInvoices>
          <TableInvoices>
            <div>
              <div>
                <span>Total</span>
                <span>
                  {`R$ ${total.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}
                </span>
              </div>
            </div>
          </TableInvoices>
        </Top>
        <Bottom>
          <img src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/information.svg`} alt="InfoIcon" />
          <span>
            {`Seu pagamento foi recebido e está sendo processado. Enviaremos um e-mail com o comprovante após a confirmação. Caso haja necessidade de verificações extras, a ${process.env.REACT_APP_HELMET_OG_TITLE} entrará em contato.`}
          </span>
        </Bottom>
        <ButtonComprov className="proofButton" onClick={printProof} isActive={!hasPrint}>CARREGAR COMPROVANTE</ButtonComprov>
        {hasPrint && printProofPayment && (
          <PDFDownloadLink
            style={{ marginBottom: 20, textDecoration: 'none', color: ' #fff',display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 48, border: 'none', fontSize: 16, fontFamily: 'Roboto', fontWeight: 500, backgroundColor: 'var(--primaryColor)'}}
            document={<ProofPayment proof={printProofPayment} />}
            fileName="comprovante.pdf"
          >
            {({ blob, url, loading, error }) => loading ? (
              <ClipLoader color={color} loading={loading} size={35} />
            ) : (
              'DOWNLOAD DO COMPROVANTE'
            )}
          </PDFDownloadLink>
        )}
        <Button className="homeButton" onClick={()=>{history.push('/login')}}>IR PARA A TELA INICIAL</Button>
      </Box>
      <div style={{width: 50, height: 10}} />
    </Container>
  );
}
export default Success;
