/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable camelcase */
/* eslint-disable radix */
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  faArrowLeft,
  faChevronDown,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { validateCPF } from 'validations-br'
import { nanoid } from 'nanoid'
import { ReCaptcha } from 'react-recaptcha-v3'

import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { AxiosResponse } from 'axios'

import Select from '../../components/Select'

import {
  Box,
  Container,
  Header,
  Title,
  Body,
  Left,
  Right,
  BtnBack,
  ButtonPay,
  ScrollInvoice,
  InvoiceDetails,
  TitleInvoiceDetails,
  DescriptionInvoice,
  BodyContainer,
} from './styles'
import InputMask from '../../components/InputMask'
import Input from '../../components/Input'
import { api, apiPaymentLink, mundiPagg } from '../../services/api'
import {
  PaymentDTO,
  FaturaDTO,
  MundiPaggDTO,
  MundiReturnDTO,
  User,
} from '../../utils/models'
import Footer from '../../components/Footer'
import Modal from '../Payment/components/Modal'
import HeaderComponent from './components/Header'
import Success from './components/Success'
import ErrorComp from './components/Error'
import { checkIfNewTypeFixed } from '../../utils/checkIfNewTypeFixed'

const TWO_MINUTES = 10 * 60000

// import { isAuthenticated } from '../../utils/auth'

interface LocationStateDTO {
  data: PaymentDTO
}
interface Errors {
  [key: string]: string
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Payment: React.FC = () => {
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const thisRef = useRef<any>(null)
  const [userInfo, setUserInfo] = useState<User>()
  const [loadingPayment, setLoadingPayment] = useState(false)
  const location = useLocation<LocationStateDTO>()
  const [paymentResume, setPaymentResume] = useState<PaymentDTO>()
  // const [linkId,setLinkId] = useState('')
  const [openDetail, setOpenDetail] = useState<number>()
  const [total, setTotal] = useState<number>(0)
  const [listInvoicePay, setList] = useState<Array<FaturaDTO>>()
  const [invoideId, setInvoiceId] = useState<Array<FaturaDTO>>()
  const [loadingErase, setLoadingErase] = useState<boolean>(false)
  const [optionsSelect, setOptionsSelect] = useState<Array<any>>()
  const [parcelamentosOptions, setParcelamentosOptions] = useState<any[]>([])

  const [UCToken,setUCToken] = useState<string|undefined>()

  const [showModalErrorMundi, setShowModalErrorMundi] = useState(false)

  const [limited, setLimited] = useState(true)

  const params = useParams()
  const query = useQuery()
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false)

  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  )
  const [loadingCaptcha, setLoadingCaptcha] = useState(true)
  const [captcha, setCaptcha] = useState<ReCaptcha>()

  const [paymentState, setPaymentState] = useState<string>('')

  const [loadingInfo, setLoadingInfo] = useState(true)

  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        if (googleRecaptcha) {
          // console.log('recaptcha')
          setGoogleRecaptcha(undefined)
          captcha?.execute()
        }
      }, TWO_MINUTES)
  }, [googleRecaptcha])

  const verifyCallback = (recaptchaToken: string): void => {
    setLoadingCaptcha(false)
    setGoogleRecaptcha(recaptchaToken)
  }

  useEffect(() => {
    if (!googleRecaptcha) {
      setLoadingCaptcha(true)
    } else {
      setLoadingCaptcha(false)
    }
  }, [googleRecaptcha])

  useEffect(() => {
    if (paymentResume && googleRecaptcha) handleUC(paymentResume?.uc)
  }, [paymentResume, googleRecaptcha])

  // eslint-disable-next-line no-shadow
  async function getPaymentLink(linkId: string, secret: string) {
    console.log('start')
    if (paymentLinkLoading) return
    setPaymentLinkLoading(true)
    try {
      const pass = process.env.REACT_APP_PAYMENT_LINK_PASS || ''
      const uname = process.env.REACT_APP_PAYMENT_LINK_UNAME || ''
      const response = await apiPaymentLink.get(
        `/link/${linkId}?secret=${secret}`,
        {
          auth: {
            password: pass,
            username: uname,
          },
        }
      )
      if (response.data) {
        if (response.data.pagamento) {
          setPaymentResume(response.data.pagamento)
          // console.log(JSON.stringify(response.data.pagamento))
          localStorage.setItem(
            'paymentResume',
            JSON.stringify(response.data.pagamento)
          )
          // handleUC(response.data.pagamento.uc)
        }
      }
      setPaymentLinkLoading(false)
    } catch (error) {
      setPaymentLinkLoading(false)
      alert('Link não encontrado')
      history.push('/')
    }
  }

  useEffect(() => {
    const p = params as { linkId?: string }
    if (!p.linkId) {
      history.push('/')
    } else {
      const s = query.get('secret') // as { search?: string };
      if (s) {
        console.log(s)
        getPaymentLink(p.linkId, s)
      }
    }
  }, [params])

  // useEffect(() => {
  //   const userTemp = localStorage.getItem('info')
  //   if (userTemp) {
  //     setUserInfo(JSON.parse(userTemp))
  //   }
  // }, [])
  /*
  useEffect(() => {

  }, [location]) */

  useEffect(() => {
    // setLimited(modules.LIMITED)

    const infoPermit = sessionStorage.getItem('infoPermission') === 'true'

    setLimited(!infoPermit)
  }, [])

  function roundedValue(resume: any): any {
    let x = '' as any
    x = `${resume}`.split('.')
    if (x.length > 1) {
      x = `0.${x[1]}`
    } else {
      x = `0.00`
    }
    x = parseFloat(x)
    x *= 100
    x = Math.round(x)
    x = Math.trunc(resume) + x / 100

    return x
  }

  function translatePaymentResumeFaturas(faturas: FaturaDTO[]) {
    const translated = faturas.map((fatura) => {
      return { ...fatura, C1_TOTAL_CORRIGIDO: fatura.total }
    })

    return translated
  }

  function setTotalFromParcelamentos(qtd: number) {
    if (parcelamentosOptions && parcelamentosOptions.length > 0) {
      const find = parcelamentosOptions.find(
        (parcela) => parcela.numParcelas === qtd
      )
      if (find) {
        setTotal(find.total / 100)
      }
    }
  }

  async function getParcelamentos() {
    if (paymentResume)
      try {
        const token = UCToken

        const translated = translatePaymentResumeFaturas(paymentResume?.faturas)

        const postData = {
          uc: userInfo?.IDENT_UC,
          faturas: translated,
        }

        const response = await api.post(`/pagamento/parcelamento`, postData, {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        })

        const { data } = response
        if (data && data.parcelamentos) {
          const tempOptions = [] as any[]

          data.parcelamentos.forEach((parcela: any) => {
            let label = ''
            label = `${parcela.numParcelas}x R$ ${(
              parcela.valor / 100
            ).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} (${
              parcela.juros !== 0
                ? `${parcela.juros / 100}% de juros`
                : 'sem juros'
            })${
              parcela.juros === 0
                ? ''
                : ` (Total R$ ${(parcela.total / 100).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })})`
            }`
            const item = {
              value: parcela.numParcelas,
              label,
            }
            tempOptions.push(item)
          })
          setParcelamentosOptions(data.parcelamentos)
          // console.log(tempOptions,'aaa')
          setOptionsSelect(tempOptions)
        } else {
          backButton()
        }
      } catch (error) {
        console.log(error)
        backButton()
      }
  }

  React.useEffect(() => {
    if (userInfo && paymentResume && UCToken) {
      getParcelamentos()
    }
  }, [userInfo, paymentResume,UCToken])

  useEffect(() => {
    if (paymentResume?.faturas.length === 1) {
      setOpenDetail(paymentResume?.faturas[0].id)
    }
    if (paymentResume) setTotal(paymentResume?.total)
    const temp = paymentResume?.faturas
    setInvoiceId(temp)
    // console.log(paymentResume?.faturas)
    setList(paymentResume?.faturas)
    const tempOption = []

    if (paymentResume?.faturas) {
      const altInstallment = checkIfNewTypeFixed(paymentResume?.faturas)

      if (altInstallment) {
        for (let i = 1; i <= 12; i += 1) {
          const resume = paymentResume.total / i
          const rounded = roundedValue(resume)
          console.log(rounded)

          tempOption.push({
            value: i,
            label: `${i}x R$ ${rounded.toLocaleString('pt-BR', {
              // minimumFractionDigits: 2,
              // maximumFractionDigits: 2,
            })} (sem juros)`,
          })
        }
      } else if (process.env.REACT_APP_INSTALLMENT_TYPE === 'FIXED') {
        const MAX_INSTALLMENT = parseInt(
          process.env.REACT_APP_MAX_INSTALLMENT || '0'
        )

        if (MAX_INSTALLMENT)
          for (let i = 1; i < MAX_INSTALLMENT + 1; i += 1) {
            const resume = paymentResume.total / i

            const rounded = roundedValue(resume)
            console.log(rounded)
            // console.log(x,resume)
            tempOption.push({
              value: i,
              label: `${i}x R$ ${rounded.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} (sem juros)`,
            })
          }
      } else {
        for (
          let i = 1;
          paymentResume?.faturas.length > 6
            ? i <= 6
            : i <= paymentResume?.faturas.length;
          i += 1
        ) {
          const resume = paymentResume.total / i
          const rounded = roundedValue(resume)
          console.log(rounded)

          tempOption.push({
            value: i,
            label: `${i}x R$ ${rounded.toLocaleString('pt-BR', {
              // minimumFractionDigits: 2,
              // maximumFractionDigits: 2,
            })} (sem juros)`,
          })
        }
      }
    }
    setOptionsSelect(tempOption)
  }, [paymentResume])

  async function removeInvoice(idInvoice: number): Promise<void> {
    setLoadingErase(true)
    const token = sessionStorage.getItem('token')
    try {
      if (paymentResume?.faturas.length === 1) {
        throw new Error('')
      }
      const { data } = await api.patch(
        `pagamento/${paymentResume?.id}/fatura`,
        {
          faturas: [idInvoice],
        },
        {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      setPaymentResume(data)
      setLoadingErase(false)
    } catch (error) {
      setLoadingErase(false)
      history.push('/')
    }
  }

  function consultDataInput(): void {
    if (formRef.current) {
      generateTokenMundi({
        number: formRef.current.getData().cardNumber.replace(/\s/g, ''),
        holder_name: formRef.current.getData().name,
        exp_month: parseInt(formRef.current.getData().valid?.substr(0, 2)),
        exp_year: parseInt(formRef.current.getData().valid?.substr(3, 4)),
        cvv: formRef.current.getData().cvv,
        label: '',
      })
    }
  }

  async function generateTokenMundi(card: MundiPaggDTO): Promise<void> {
    try {
      setLoadingPayment(true)
      const { data } = await mundiPagg.post(
        `/tokens?appId=${process.env.REACT_APP_API_MUNDI}`,
        {
          type: 'card',
          card,
        }
      )
      pay(data)
    } catch (err: any) {
      setLoadingPayment(false)
      if (err.status === 500) {
        setShowModalErrorMundi(true)
      } else if (formRef.current) {
        formRef.current.setErrors({
          cardNumber: 'Dados do cartão inválidos',
          cvv: 'Dados do cartão inválidos',
          valid: 'Dados do cartão inválidos',
          name: 'Dados do cartão inválidos',
        })

        if (thisRef && thisRef.current) {
          // console.log('')

          thisRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }
    }
  }

  function createUCToken(UC: string): string {
    let token = ''
    if (process.env.REACT_APP_TOKEN) {
      token = process.env.REACT_APP_TOKEN + UC
      const d = new Date()
      const timezone = 'America/Sao_Paulo'
      const zonedDate = utcToZonedTime(d, timezone)

      const formatedDate = format(zonedDate, 'ddMMyyyy')

      token += formatedDate
    }
    console.log('-login', token)

    return token
  }

  async function handleUC(docField: string): Promise<void> {
    // setLoadingUC(true)
    const noEncript = `${nanoid()}:${
      process.env.REACT_APP_TOKEN ? createUCToken(docField) : nanoid()
    }`
    const baseEncript = btoa(noEncript)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )

    try {
      const { data } = await api.get<any, AxiosResponse<User>>(
        `/ref/${docField}/current`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      setLoadingInfo(false)
      // dispatch(setClient(modules, data))
      // dispatch(setNameClient(modules, data.NOME_CLIENTE || 'SEM NOME'))
      // dispatch(setLimited(modules,true))
      sessionStorage.setItem('infoPermission', 'false')
      localStorage.setItem('infoClient', JSON.stringify(data))
      localStorage.setItem('info', JSON.stringify(data))
      setUserInfo(data)
    } catch (error) {
      console.log(error)
    } finally{
     setUCToken(baseEncript)

    }
  }

  async function pay(dataMuni: MundiReturnDTO): Promise<void> {
    const token = sessionStorage.getItem('token')
    if (
      formRef.current // && token
    ) {
      try {
        const { data } = await api.post(
          `/pagamento/${paymentResume?.id}/processar`,
          {
            transacoes: [
              {
                valor: total,
                cliente: paymentResume?.cliente,
                nomePortador: formRef.current.getData().name,
                telefone: formRef.current.getData().phone,
                email: formRef.current.getData().email,
                doc: String(formRef.current.getData().document).replace(
                  /[^\d]+/g,
                  ''
                ),
                token: dataMuni.id,
                first_six_digits: dataMuni.card.first_six_digits,
                last_four_digits: dataMuni.card.last_four_digits,
                tipo: 1,
                parcelas: formRef.current.getData().plots,
              },
            ],
          },
          {
            headers: {
              // Authorization: t,
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        setLoadingPayment(false)
        console.log(data)
        if ([4, 5].includes(data.status)) {
          localStorage.setItem('statusPaymentResume', JSON.stringify(data))
          setPaymentState('sucess')
          // history.push('/pagamento/link/sucess')
        } else {
          localStorage.setItem('paymentResume', JSON.stringify(data))
          setPaymentState('error')
          // history.push('/pagamento/link/error')
        }
      } catch (error) {
        setPaymentState('error')
        // history.push('/pagamento/link/error')
        setLoadingPayment(false)
      }
    }
  }

  async function handleSubmit(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          cardNumber: Yup.string()
            .min(15, 'Formato inválido')
            .required('O número do cartão é obrigatório'),
          valid: Yup.string()
            .min(5)
            .max(5)
            .required('A validade é obrigatória'),
          name: Yup.string()
            .min(4, 'Nome inválido')
            .required('O nome é obrigatório'),
          document: Yup.string()
            .test('is-cpf', 'CPF inválido.', (value) =>
              validateCPF(formRef.current?.getData().document)
            )
            .min(14, 'No mínimo 11 dígitos.')
            .required('O CPF é obrigatório'),
          cvv: Yup.string()
            .min(3, 'Formato Inválido')
            .max(4, 'Formato Inválido')
            .required('O código verificador é obrigatório'),
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
          email: Yup.string()
            .email('Formato de email inválido')
            .required('O email é obrigatório'),
          plots: Yup.number().required(
            'A quantidade de parcelas é obrigatória'
          ),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
        consultDataInput()
      } catch (err) {
        // console.log(err)
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }

        if (thisRef && thisRef.current) {
          // console.log('')

          thisRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }
    }
  }

  async function checkCardNumber(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          cardNumber: Yup.string()
            .min(15, 'Formato inválido')
            .required('O número do cartão é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)

          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkValid(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          valid: Yup.string()
            .min(5)
            .max(5)
            .required('A validade é obrigatória'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkName(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .min(6, 'Nome inválido')
            .required('O nome é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkCvv(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          cvv: Yup.string()
            .min(3, 'Tamanho inválido')
            .max(4, 'Tamanho inválido')
            .required('O código verificador é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkPhoneNumber(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkEmail(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Formato de email inválido')
            .required('O email é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkPlots(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          plots: Yup.number().required(
            'A quantidade de parcelas é obrigatória'
          ),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  async function checkDocNumber(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.string()
          .min(14, 'No mínimo 11 dígitos.')
          .required('O CPF é obrigatório')

        await schema.validate(formRef.current.getData().document, {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) console.log(formRef.current)
          formRef.current.setErrors(validationErrors)
          if (thisRef && thisRef.current) {
            // console.log('')

            thisRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        }
      }
    }
  }

  function cutName(name: string | undefined): string {
    if (name === undefined) {
      return 'NOME'
    }
    if (name.length > 22) {
      return `${name.substring(0, 22)}...`
    }
    return name
  }

  function backButton(): void {
    history.push({
      pathname: '/login',
      state: {
        data: invoideId,
      },
    })
  }

  return (
    <BodyContainer>
      <ReCaptcha
        ref={(e) => {
          if (e) setCaptcha(e)
        }}
        sitekey={process.env.REACT_APP_RECAPTCHA as string}
        action="auth_device"
        verifyCallback={verifyCallback}
      />
      <Container>
        <HeaderComponent
          nameClient={paymentResume?.cliente || ''}
          ucClient={paymentResume?.uc || ''}
        />
        <Modal showModalErrorMundi={showModalErrorMundi} />
        {loadingInfo ? (
          <img
            src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading-primary.svg`}
            alt="Loading"
            style={{ height: 160, position: 'absolute', top: '50%' }}
          />
        ) : paymentState === 'sucess' ? (
          <Success />
        ) : paymentState === 'error' ? (
          <ErrorComp
            returnToPayment={() => {
              setPaymentState('')
            }}
          />
        ) : (
          <Box>
            <Header>
              <Title>Pagar com Cartão de Crédito</Title>
              <BtnBack type="button" onClick={backButton}>
                <FontAwesomeIcon icon={faArrowLeft} />
                Voltar
              </BtnBack>
            </Header>
            <Body>
              <Left>
                <div ref={thisRef}>
                  <span>Dados do Pagamento</span>
                </div>
                <Form
                  style={{
                    padding: '0 15px',
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                  ref={formRef}
                  onSubmit={handleSubmit}
                  autoSave=""
                >
                  <div
                    style={{
                      display: 'grid',
                      gap: 15,
                      gridTemplateColumns: '3fr 1fr',
                    }}
                  >
                    <InputMask
                      type="tel"
                      onBlurCapture={checkCardNumber}
                      name="cardNumber"
                      label="Numero do Cartão de Crédito"
                      mask="9999 9999 9999 9999"
                    />
                    <InputMask
                      type="tel"
                      onBlurCapture={checkValid}
                      name="valid"
                      label="Validade"
                      maxLength={5}
                      mask="99/99"
                    />
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gap: 15,
                      gridTemplateColumns: '3fr 1fr',
                    }}
                  >
                    <Input
                      onBlurCapture={checkName}
                      name="name"
                      label="Nome do Titular"
                    />
                    <InputMask
                      type="tel"
                      onBlurCapture={checkCvv}
                      name="cvv"
                      label="CVV"
                      mask="9999"
                    />
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gap: 15,
                      gridTemplateColumns: '2fr 2fr',
                    }}
                  >
                    <InputMask
                      type="tel"
                      onBlurCapture={checkDocNumber}
                      name="document"
                      label="CPF"
                      mask="999.999.999-99"
                    />
                    <InputMask
                      type="tel"
                      onBlurCapture={checkPhoneNumber}
                      name="phone"
                      label="Telefone"
                      mask="(99) 99999-9999"
                    />
                  </div>
                  <Input
                    onBlurCapture={checkEmail}
                    name="email"
                    label="Email"
                  />
                  <Select
                    options={optionsSelect}
                    onBlurCapture={checkPlots}
                    name="plots"
                    label="Quantidade de parcelas"
                    onChange={(e) => {
                      if (e && e.value !== undefined) {
                        setTotalFromParcelamentos(e.value)
                      }
                    }}
                  />
                </Form>
              </Left>
              <Right>
                <div>
                  <div>
                    <span style={{ marginBottom: 12 }}>
                      Resumo do Pagamento
                    </span>
                  </div>
                  {!limited && (
                    <div style={{ padding: '10px 15px 0 15px' }}>
                      <span
                        style={{
                          fontSize: 13,
                          fontFamily: 'Roboto',
                          fontWeight: 300,
                          color: '#494949',
                        }}
                      >
                        Cliente / UC
                      </span>
                      <h4
                        style={{
                          fontSize: 18,
                          fontFamily: 'Roboto',
                          fontWeight: 500,
                          color: '#494949',
                        }}
                      >
                        {`${cutName(userInfo?.NOME_CLIENTE || '...')} / ${
                          userInfo?.IDENT_UC || ''
                        }`}
                      </h4>
                    </div>
                  )}
                  <span
                    style={{
                      fontSize: 13,
                      fontFamily: 'Roboto',
                      fontWeight: 300,
                      color: '#494949',
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    {`Faturas selecionadas ${listInvoicePay?.length}`}
                  </span>
                  <ScrollInvoice>
                    <div>
                      {listInvoicePay?.map((invoice) => (
                        <InvoiceDetails
                          key={invoice.id}
                          style={{
                            transition: '0.4s',
                            // eslint-disable-next-line no-nested-ternary
                            height:
                              openDetail === invoice.id
                                ? invoice.obrigatoria
                                  ? 108
                                  : 143
                                : 40,
                          }}
                        >
                          <button
                            onClick={() => {
                              if (openDetail === invoice.id) {
                                setOpenDetail(undefined)
                              } else {
                                setOpenDetail(invoice.id)
                              }
                            }}
                            type="button"
                            style={{
                              border: 'none',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                              background: 'transparent',
                            }}
                          >
                            <TitleInvoiceDetails>
                              {`Fatura ${invoice.ref}`}
                            </TitleInvoiceDetails>
                            <div>
                              <TitleInvoiceDetails>
                                {`R$ ${invoice.total.toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}`}
                              </TitleInvoiceDetails>
                              <button
                                type="button"
                                style={{
                                  background: 'transparent',
                                  marginLeft: 10,
                                  marginRight: 8,
                                  border: 'none',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  style={{
                                    transition: '0.4s',
                                    transform:
                                      openDetail === invoice.id
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                  }}
                                  color="var(--primaryColor)"
                                />
                              </button>
                            </div>
                          </button>
                          <div
                            style={{
                              transition: '0.2s',
                              overflow: 'hidden',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div style={{ paddingBottom: 10 }}>
                              <DescriptionInvoice>{`Venc. ${invoice.vencimento}`}</DescriptionInvoice>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <DescriptionInvoice>
                                  Valor original
                                </DescriptionInvoice>
                                <DescriptionInvoice>
                                  {`R$ ${invoice.fatura.toLocaleString(
                                    'pt-BR',
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}`}
                                </DescriptionInvoice>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontFamily: 'Roboto',
                                    color: '#494949',
                                    lineHeight: 1.5,
                                  }}
                                >
                                  Juros + Multa + Correção
                                </span>
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontFamily: 'Roboto',
                                    color: '#494949',
                                    fontWeight: 500,
                                  }}
                                >
                                  {`R$ ${(
                                    invoice.correcao +
                                    invoice.juros +
                                    invoice.multa
                                  ).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                  })}`}
                                </span>
                              </div>
                            </div>
                            {invoice.obrigatoria ? null : (
                              <button
                                onClick={() => {
                                  removeInvoice(invoice.id)
                                }}
                                type="button"
                                style={{
                                  background: '#fff',
                                  width: '100%',
                                  height: 35,
                                  borderRadius: 8,
                                  border: `1px solid ${
                                    loadingErase ? '#494949' : '#D93B3C'
                                  } `,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  style={{ transition: '0.4s' }}
                                  color={loadingErase ? '#494949' : '#D93B3C'}
                                />
                                <span
                                  style={{
                                    color: loadingErase ? '#494949' : '#D93B3C',
                                    fontSize: 13,
                                    fontFamily: 'Roboto',
                                    fontWeight: 500,
                                    marginLeft: 10,
                                  }}
                                >
                                  {loadingErase
                                    ? 'REMOVENDO'
                                    : 'REMOVER ESTA FATURA'}
                                </span>
                              </button>
                            )}
                          </div>
                        </InvoiceDetails>
                      ))}
                    </div>
                  </ScrollInvoice>
                  <ButtonPay
                    type="button"
                    onClick={handleSubmit}
                    disabled={loadingPayment}
                    style={{ cursor: !loadingPayment ? 'pointer' : 'initial' }}
                  >
                    {loadingPayment ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/loading.svg`}
                        alt="Loading"
                        style={{ height: 50 }}
                      />
                    ) : (
                      `Pagar R$ ${total.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                      })}`
                    )}
                  </ButtonPay>
                </div>
                <div
                  style={{
                    height: 80,
                    background: '#fff',
                    borderRadius: 8,
                    padding: 15,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${process.env.REACT_APP_FOLDER_IMAGE}/information.svg`}
                    alt="Icon de Informação"
                    style={{ height: 30, marginLeft: 10, marginRight: 20 }}
                  />
                  <span
                    style={{
                      fontSize: 13,
                      fontFamily: 'Roboto',
                      fontWeight: 300,
                    }}
                  >
                    Em caso de faturas pagas em atraso, os encargos referentes a
                    iluminação pública serão cobrados nas próximas faturas.
                  </span>
                </div>
              </Right>
            </Body>
            <div id="mobile">
              <Footer />
            </div>
          </Box>
        )}
        <div id="web" style={{ width: '100%' }}>
          <Footer />
        </div>
      </Container>
    </BodyContainer>
  )
}

export default connect((state: any) => ({ modules: state }))(Payment)
